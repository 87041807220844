import { laptopMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const SiteMapFooterSection = styled.section`
  border-top: 1px solid ${(props) => props.theme.backgroundLight};
  border-bottom: 1px solid ${(props) => props.theme.backgroundLight};
  margin-top: 30px;
`;

export const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 2000px;
  margin: 0 auto;
`;

export const LinkSection = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 33%;
  margin-bottom: 15px;

  @media (min-width: 1570px) {
    width: 24%;
  }

  @media (${laptopMax}) {
    width: 50%;
  }

  @media (${tabletMax}) {
    width: 100%;
  }
`;

export const Links = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  margin-top: 5px;
  justify-content: space-between;
`;

export const LinkItem = styled.li`
  width: 49%;
  margin-bottom: 3px;

  a:hover p {
    color: ${(props) => props.theme.backgroundLight};
  }
`;
