/* istanbul ignore file */
/* eslint-disable */

export function displayConsentUi() {
  if (typeof googlefc !== 'undefined' && typeof googlefc.showRevocationMessage === 'function') {
    googlefc.showRevocationMessage();
  } else if (typeof window.__tcfapi === 'function') {
    window.__tcfapi('displayConsentUi', 2, function () {});
  }
}

export default displayConsentUi;
