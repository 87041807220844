import searchPresets from '@propertypal/shared/src/constants/searchPresets.json';
import alertIcon from '@propertypal/shared/src/resources/icons/tabs/alert.svg?url';
import favouritesIcon from '@propertypal/shared/src/resources/icons/tabs/favourites.svg?url';
import homeIcon from '@propertypal/shared/src/resources/icons/tabs/home.svg?url';
import menuIcon from '@propertypal/shared/src/resources/icons/tabs/menu.svg?url';
import searchIcon from '@propertypal/shared/src/resources/icons/tabs/search.svg?url';
import togglePageScroll from '@propertypal/web-app/src/utils/togglePageScroll';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Text } from '../typography';
import { Container, CustomButton, Item, Links, Icon } from './MobileFooter.style';

const MorePanel = dynamic(() => import('./MorePanel'), { ssr: false });

interface Props {
  cfIpCountry: string | null;
}

const NAV_LINKS = [
  {
    title: 'Home',
    shortTitle: 'Home',
    icon: homeIcon,
    testID: 'homeTab',
    url: '/',
    isActive: (url: string) => url === '/',
  },
  {
    title: 'Search',
    shortTitle: 'Search',
    icon: searchIcon,
    testID: 'searchTab',
    url: '/property-for-sale',
    isActive: (url: string) => {
      let active = false;

      searchPresets.urls.forEach((presetUrl) => {
        if (url.includes(presetUrl)) active = true;
      });

      return active || url.startsWith('/search') || url.startsWith('/map');
    },
  },
  {
    title: 'Favourites',
    shortTitle: 'Favourites',
    icon: favouritesIcon,
    testID: 'savedTab',
    url: '/my/favourites',
    isActive: (url: string) => url === '/my/favourites',
  },
  {
    title: 'Saved Searches',
    shortTitle: 'Saved',
    icon: alertIcon,
    testID: 'alertsTab',
    url: '/my/alerts',
    isActive: (url: string) => url === '/my/alerts',
  },
];

const MobileFooter: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const [activeMore, setActiveMore] = useState<boolean>(false);

  const toggleMoreMenu = () => {
    setActiveMore(!activeMore);
    togglePageScroll('#moreMenu', activeMore);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (activeMore) {
        // close menu on route.
        setActiveMore(false);
        togglePageScroll('#moreMenu', true);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [activeMore]);

  return (
    <Container className="hide-with-keyboard">
      <Links role="menu">
        {NAV_LINKS.map((item) => {
          const active = item.isActive(router.asPath) && !activeMore;

          return (
            <Item role="none" key={item.title}>
              <Link href={item.url} prefetch={false} tabIndex={0} role="menuitem" data-testid={item.testID}>
                {item.icon && <Icon {...item.icon} $active={active} alt="tab icon" loading="lazy" />}

                <Text
                  color={active ? theme.primary : theme.darkGrey}
                  fontWeight={active ? 'bold' : 'normal'}
                  align="center"
                >
                  <span className="itemTitle">{item.title}</span>
                  <span className="itemShortTitle">{item.shortTitle}</span>
                </Text>
              </Link>
            </Item>
          );
        })}

        <Item role="none">
          <CustomButton role="menuitem" data-testid="moreTab" tabIndex={0} onClick={toggleMoreMenu}>
            <Icon {...menuIcon} alt="menu icon" $active={activeMore} loading="lazy" />

            <Text
              color={activeMore ? theme.primary : theme.darkGrey}
              fontWeight={activeMore ? 'bold' : 'normal'}
              align="center"
            >
              Menu
            </Text>
          </CustomButton>

          <MorePanel cfIpCountry={props.cfIpCountry} open={activeMore} />
        </Item>
      </Links>
    </Container>
  );
};

export default MobileFooter;
