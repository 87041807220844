/* eslint-disable no-undef */
import dayjs from 'dayjs';
import { NetworkContext } from '../types/network';
import isJSON from '../utils/isJSON';

export const getItem = async (key: string, _?: NetworkContext) => {
  let value = null;

  const parts = document.cookie.split('; ').find((row) => row.startsWith(`${key}=`));

  if (parts) {
    value = parts.replace(`${key}=`, '');
  }

  if (typeof value === 'string') {
    return isJSON(value) ? JSON.parse(value) : value;
  }

  return value;
};

export const setItem = async (key: string, value: any, _?: NetworkContext) => {
  const expires = dayjs().add(90, 'days').toDate();
  const jValue = JSON.stringify(value);

  document.cookie = `${key}=${jValue}; expires=${expires.toUTCString()}; SameSite=Lax; path=/`;

  return null;
};

export const removeItem = async (key: string, _?: NetworkContext) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax; path=/`;

  return null;
};
