import { getItem, setItem } from '../../services/storage';
import { NetworkContext } from '../../types/network';
import { Property } from '../../types/property';
import { PropertySearchResult } from '../../types/propertySearch';

const ORDER_KEY = 'orderKey';
type CurrentOrder = number[] | null;

const idMatchCheck = (currentOrder: CurrentOrder, properties: Property[]) => {
  const matches: number[] = [];

  if (!currentOrder) {
    return false;
  }

  properties.forEach((property) => {
    if (currentOrder.includes(property.id) && !matches.includes(property.id)) {
      matches.push(property.id);
    }
  });

  return matches.length === currentOrder.length;
};

export const storeIdOrder = (properties: Property[], ctx?: NetworkContext) => {
  const ids: number[] = [];

  properties.forEach((property) => {
    if (property.featuredListing && property.type === 'property') {
      ids.push(property.id);
    }
  });

  return setItem(ORDER_KEY, ids, ctx);
};

const orderFeaturedProperties = async (
  data: PropertySearchResult,
  ctx?: NetworkContext,
): Promise<PropertySearchResult> => {
  const currentOrder: CurrentOrder = await getItem(ORDER_KEY, ctx);
  const orderMatch = idMatchCheck(currentOrder, data.results);

  if (orderMatch && currentOrder) {
    const properties = [...data.results];
    const mProperties: Property[] = [];

    currentOrder.forEach((id) => {
      const index = properties.findIndex((p) => p.id === id);
      if (index >= 0) {
        const property = properties.splice(index, 1);
        mProperties.push(property[0]);
      }
    });

    const orderedProperties = mProperties.concat(properties);

    return {
      ...data,
      results: orderedProperties,
    };
  }

  await storeIdOrder(data.results, ctx);

  return data;
};

export default orderFeaturedProperties;
