const getUtf8Bytes = (str: string) => {
  return new Uint8Array([...decodeURIComponent(encodeURIComponent(str))].map((c) => c.charCodeAt(0)));
};

const hmac = async (message: string, key: string) => {
  const k = getUtf8Bytes(key);
  const m = getUtf8Bytes(message);
  const c = await crypto.subtle.importKey('raw', k, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign']);
  const s = await crypto.subtle.sign('HMAC', c, m);
  const b = new Uint8Array(s);
  return Array.prototype.map.call(b, (x) => x.toString(16).padStart(2, '0')).join('');
};

export default hmac;
