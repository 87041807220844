import { createSlice } from '@reduxjs/toolkit';
import { LatLng, MapBounds } from '../../types/map';
import { MarkerType } from '../../types/marker';
import { SavedSearch, SearchParams } from '../../types/search';
import wrapApiPolygon from '../../utils/polygons';
import { RootState } from '../store';

export interface MapState {
  error?: Error;
  markers?: MarkerType[];
  loading: boolean;
  polygons?: object;
  bounds?: MapBounds;
  savable: boolean;
  savedSearch?: SavedSearch['search'];
  centre?: LatLng;
  zoom?: number;
  url?: string;
  northWest?: LatLng;
  southEast?: LatLng;
  params?: SearchParams;
  description?: {
    complete: string;
    details: string;
    stem: string;
    metaDescription: string;
    short: string;
  };
}

const initialState: MapState = {
  loading: false,
  savable: false,
};

interface SetError {
  payload: Error;
}

interface SetSavedSearch {
  payload: SavedSearch['search'] | undefined;
}

interface SetMarkers {
  payload: {
    markers?: MarkerType[];
    bounds?: MapBounds;
    savable: boolean;
    savedSearch?: SavedSearch['search'];
    centre?: LatLng;
    zoom?: number;
    url?: string;
    northWest?: LatLng;
    southEast?: LatLng;
    params: SearchParams;
    description: MapState['description'];
  };
}

interface SetLoading {
  payload: boolean;
}

interface SetPolygons {
  payload: object;
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    setSavedSearch: (state, action: SetSavedSearch) => {
      if (state.markers) {
        state.savedSearch = action.payload;
      }
    },
    setMarkers: (state, action: SetMarkers) => {
      state.markers = action.payload.markers;
      state.bounds = action.payload.bounds;
      state.savable = action.payload.savable;
      state.savedSearch = action.payload.savedSearch;
      state.zoom = action.payload.zoom;
      state.centre = action.payload.centre;
      state.southEast = action.payload.southEast;
      state.northWest = action.payload.northWest;
      state.url = action.payload.url;
      state.params = action.payload.params;
      state.description = action.payload.description;
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setPolygons: (state, action: SetPolygons) => {
      if (action.payload) {
        state.polygons = wrapApiPolygon(action.payload);
      } else {
        state.polygons = undefined;
      }
    },
    clearPolygons: (state) => {
      state.polygons = undefined;
    },
  },
});

export const { clearPolygons, setPolygons, setSavedSearch, setError, setMarkers, setLoading } = mapSlice.actions;

export const selectMap = (state: RootState) => state.map;

export default mapSlice.reducer;
