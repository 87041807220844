import router from 'next/router';
import React, { useEffect, useState } from 'react';
import { ProgressBox, ProgressLine } from './PageProgress.style';

let ANIMATION_START = 1;

const PageProgress = () => {
  const [active, setActive] = useState(false);
  const [animationDuration, setAnimationDuration] = useState('1s');

  useEffect(() => {
    let url = window.location.pathname;

    const startAnimation = (nextUrl: string, { shallow }: { shallow: boolean }) => {
      if (url !== nextUrl && !shallow) {
        ANIMATION_START = new Date().getTime();
        setActive(true);
      }
    };

    const endAnimation = (nextUrl: string, { shallow }: { shallow: boolean }) => {
      if (url !== nextUrl && !shallow) {
        setAnimationDuration(`${new Date().getTime() - ANIMATION_START}ms`);
        setActive(false);
      }

      url = nextUrl;
    };

    router.events.on('routeChangeStart', startAnimation);
    router.events.on('routeChangeComplete', endAnimation);

    return () => {
      router.events.off('routeChangeStart', startAnimation);
      router.events.off('routeChangeComplete', endAnimation);
    };
  }, []);

  return (
    <ProgressBox style={{ visibility: active ? 'visible' : 'hidden' }}>
      <ProgressLine style={{ width: active ? '99%' : '0%', animationDuration }} data-testid="progressLine" />
    </ProgressBox>
  );
};

export default PageProgress;
