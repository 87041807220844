export const MY_DRAWN_AREA = 'My Drawn Area';

export const MY_TRAVEL_TIME_AREA = 'My Travel Time Area';

export const MY_LOCATION = 'My Location';

export const ALERT_OPTIONS = [
  { label: 'Never', value: 'never' },
  { label: 'Instant', value: 'instant' },
  { label: 'Twice Daily', value: 'twiceDaily' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
];
