/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import { Dispatch } from 'react';
import * as storage from '../services/storage';
import { NetworkContext } from '../types/network';
import { clearClientToken, EMAIL_HASHES, PROFILE_IMAGE } from './auth';

const logout = async (_?: Dispatch<any>, ctx?: NetworkContext) => {
  await storage.removeItem(PROFILE_IMAGE, ctx);
  await storage.removeItem(EMAIL_HASHES, ctx);

  // Web only logout
  if (typeof window !== 'undefined') {
    await fetch('/api/logout', {
      method: 'GET',
      credentials: 'include',
    });

    if (!process?.env?.JEST_WORKER_ID) {
      window.location.reload();
    }
  }
  // Server only logout
  else {
    await clearClientToken(ctx);
  }
};

export default logout;
