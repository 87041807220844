interface Logging {
  logCrumb: (crumb: Crumb) => void;
  logError: (error: Error) => void;
}

export interface Crumb {
  message: string;
  metaData?: { [key: string]: any };
}

const logging: Logging = {
  logCrumb: () => {},
  logError: () => {},
};

export const initLogging = (loggingObj: Logging) => {
  logging.logError = loggingObj.logError;
  logging.logCrumb = loggingObj.logCrumb;

  return loggingObj;
};

export default logging;
