import { useCallback } from 'react';

interface Options {
  leading: boolean;
  trailing: boolean;
}

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number,
  options: Options = { leading: false, trailing: true },
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  let timeoutId: number | null;
  let resolves: any[] = [];
  let rejects: any[] = [];

  return function debounced(...args: Parameters<T>): Promise<ReturnType<T>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const context = this;
      resolves.push(resolve);
      rejects.push(reject);

      if (timeoutId && options.trailing) {
        clearTimeout(timeoutId);
      }

      if (options.trailing || !timeoutId) {
        // @ts-ignore
        timeoutId = setTimeout(() => {
          timeoutId = null;

          try {
            const result = func.apply(context, args);
            resolves.forEach((r) => r(result));
          } catch (error) {
            rejects.forEach((r) => r(error));
          }

          resolves = [];
          rejects = [];
        }, delay);
      }
    });
  };
};

const useDebounce = <F extends (...args: any[]) => any>(
  func: F,
  delay: number,
  options: Options,
  dependencies: any[],
) => {
  return useCallback(debounce(func, delay, options), dependencies);
};

export default useDebounce;
