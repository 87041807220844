import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { Container, Content } from './PageWrapper.style';

interface Props {
  containerStyle?: CSSProperties;
  className?: string;
  overflow?: string;
  noMaxWidth?: boolean;
  children: ReactNode;
}

const PageWrapper: FunctionComponent<Props> = (props) => {
  return (
    <Container className={props.className} style={props.containerStyle}>
      <Content $overflow={props.overflow} $noMaxWidth={props.noMaxWidth}>
        {props.children}
      </Content>
    </Container>
  );
};

export default PageWrapper;
