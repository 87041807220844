import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import enquiriesReducer from './enquiries/enquiries.slice';
import favouritesReducer from './favourites/favourites.slice';
import mapReducer from './map/map.slice';
import notesReducer from './notes/notes.slice';
import palsReducer from './pals/pals.slice';
import propertiesReducer from './properties/properties.slice';
import searchReducer from './search/search.slice';
import timelineReducer from './timeline/timeline.slice';
import userReducer from './user/user.slice';

// persist config not used on website
// export just maintains type parity with adjacent native file
export const searchPersistConfig = {
  key: 'search',
  storage: {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
  },
};

const createStore = (initialState?: any) => {
  return configureStore({
    reducer: {
      notes: notesReducer,
      enquiries: enquiriesReducer,
      properties: propertiesReducer,
      map: mapReducer,
      favourites: favouritesReducer,
      search: searchReducer,
      pals: palsReducer,
      user: userReducer,
      timeline: timelineReducer,
    },
    middleware: [thunk],
    preloadedState: initialState,
  });
};

export default createStore;
