import axios, { API_URL } from '../../services/axios';
import { AppThunk } from '../store';
import {
  setError,
  setTimelineLoading,
  setSummaryLoading,
  setTimeline,
  setTimelineSummary,
  setMoreTimeline,
} from './timeline.slice';

type DefaultThunk = () => AppThunk<Promise<boolean>>;

export const getTimeline: DefaultThunk = () => async (dispatch) => {
  try {
    dispatch(setTimelineLoading(true));

    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/timeline?imageLocator=145x100%3AFILL_CROP`,
      },
      dispatch,
    );

    dispatch(setTimeline(result.data));

    return true;
  } catch (error: any) {
    dispatch(setError(error));

    return false;
  } finally {
    dispatch(setTimelineLoading(false));
  }
};

export const getTimelineSummary: DefaultThunk = () => async (dispatch) => {
  try {
    dispatch(setSummaryLoading(true));

    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/timeline/summary`,
      },
      dispatch,
    );

    dispatch(setTimelineSummary(result.data));

    return true;
  } catch (error: any) {
    dispatch(setError(error));

    return false;
  } finally {
    dispatch(setSummaryLoading(false));
  }
};

export const getMoreTimeline: DefaultThunk = () => async (dispatch, getState) => {
  try {
    const pageSize = 5;
    const { timeline } = getState();

    if (timeline.results) {
      const currentPage = Math.ceil(timeline.results.content.length / pageSize) - 1;

      const page = currentPage + 1;

      dispatch(setTimelineLoading(true));

      const result = await axios(
        {
          method: 'GET',
          url: `${API_URL}/timeline?imageLocator=145x100%3AFILL_CROP`,
          params: {
            page,
            pageSize,
          },
        },
        dispatch,
      );

      dispatch(setMoreTimeline(result.data));
    }

    return true;
  } catch (error: any) {
    dispatch(setError(error));

    return false;
  } finally {
    dispatch(setTimelineLoading(false));
  }
};
