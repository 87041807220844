export const laptopLargeMax = 'max-width: 1440px';
export const laptopLargeMin = 'min-width: 1441px';
export const laptopMaxXL = 'max-width: 1280px';
export const laptopMinXL = 'min-width: 1281px';
export const laptopMax = 'max-width: 1024px';
export const laptopMin = 'min-width: 1025px';
export const laptopMediumMax = 'max-width: 980px';
export const laptopMediumMin = 'min-width: 981px';
export const laptopSmallMax = 'max-width: 900px';
export const laptopSmallMin = 'min-width: 901px';
export const largeTabletMax = 'max-width: 1024px';
export const largeTabletMin = 'min-width: 1025px';
export const tabletMax = 'max-width: 768px';
export const tabletMin = 'min-width: 768px';
export const mobileXXLargeMax = 'max-width: 600px';
export const mobileXXLargeMin = 'min-width: 601px';
export const mobileXLargeMax = 'max-width: 500px';
export const mobileXLargeMin = 'min-width: 501px';
export const mobileLargeMax = 'max-width: 425px';
export const mobileLargeMin = 'min-width: 425px';
export const mobileMax = 'max-width: 375px';
export const mobileSmallMax = 'max-width: 320px';
