import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/*
 * togglePageScroll
 * ----------------
 * key is required to ensure that if two components disable the page scroll, that both need to re-enable
 * page-scrolling before it is actually re-enabled.
 */

const togglePageScroll = (id: string, enable: boolean) => {
  const el = document.querySelector(id);

  if (el) {
    if (enable) {
      enableBodyScroll(el);
    } else {
      disableBodyScroll(el);
    }
  }
  // ensure that overflow is remove from body if element does not exist
  else document.body.style.overflow = '';
};

export default togglePageScroll;
