import styled from 'styled-components';

export const SkipLink = styled.button`
  background-color: ${(props) => props.theme.background};
  display: block;
  left: 0;
  padding: 4px;
  border-radius: 0 0 5px 0;
  position: absolute;
  transition: transform 0.3s;
  transform: translateY(-100%);

  &:focus {
    transform: translateY(0);
  }
`;

export default SkipLink;
