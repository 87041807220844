import { largeTabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

export const Container = styled.nav<{ $isHomePage?: boolean }>`
  width: 100%;
  background: ${(props) => props.theme.darkGrey};
  height: 57px;

  ${(props) =>
    props.$isHomePage &&
    `
    z-index: 20;
    position: absolute;
    background:none;
  `}

  @media print {
    display: none;
  }
`;

export const Content = styled.div<{ $isHomePage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: ${({ $isHomePage }) => ($isHomePage ? 'none' : `${MAX_WIDTH - 20}px`)};
  padding: 0 20px;
  height: 57px;
  margin: 0 auto;

  @media (${largeTabletMax}) {
    padding: 10px;
  }
`;

export const ProfilePlaceholder = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Logo = styled.img`
  width: 102px;
  height: 36px;
  cursor: pointer;
`;

export const LinksContainer = styled.ul<{ $isHomePage?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 0;

  ${(props) =>
    props.$isHomePage &&
    `
    justify-content: center;
    margin-left: -30px;

  `}
`;

export const LinkBox = styled(Link)<{ $active?: boolean }>`
  padding: 16px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  color: ${({ theme, $active }) => ($active ? theme.primary : theme.white)};
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const LinkButton = styled.button<{ $active?: boolean }>`
  background: none;
  border: none;
  padding: 16px;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  font-size: 16px;
  color: ${({ theme, $active }) => ($active ? theme.primary : theme.white)};
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  ${(props) =>
    props.$active &&
    `
    background-color: ${props.theme.darkGreyTranslucent};

    & + ul {
      display: flex;
      flex-direction: column;
    }
    
  `}
`;

export const ComponentBox = styled.ul`
  flex-direction: column;
  position: absolute;
  padding: 0;
  list-style: none;
  min-width: 200px;
  background-color: ${(props) => props.theme.darkGreyTranslucent};
  z-index: 10;
  border-radius: 0 6px 6px 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: none;

  li {
    border-bottom: 1px solid ${(props) => props.theme.textLight};
  }

  li:last-of-type {
    border-bottom: none;
  }
`;

export const TopLevelLI = styled.li`
  @media screen and (${largeTabletMax}) {
    display: none;
  }
`;
