import styled from 'styled-components';

export const ProgressBox = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 3px;
  background-color: ${(props) => props.theme.backgroundLighter};
  z-index: 999;
`;

export const ProgressLine = styled.div`
  background-color: ${(props) => props.theme.primary};
  width: 0%;
  height: 100%;
  transition-property: width;
  transition-duration: 1s;
`;
