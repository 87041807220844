export interface SerializableObject {
  [key: string]: string | string[] | number | undefined;
}

const encodeString = (param: any) => {
  return encodeURIComponent(param.toString().trim());
};

const serializeObject = (objectParams: SerializableObject) => {
  return Object.keys(objectParams)
    .filter((key) => typeof objectParams[key] !== 'undefined' && objectParams[key] !== null)
    .map((key) => {
      const param = objectParams[key];

      if (Array.isArray(param)) {
        return param
          .map((p) => {
            return `${encodeURIComponent(key)}=${encodeString(p)}`;
          })
          .join('&');
      }

      // @ts-ignore
      return `${encodeURIComponent(key)}=${encodeString(param)}`;
    })
    .join('&');
};

export default serializeObject;
