import PageWrapper from '@propertypal/web-ui/src/layout/PageWrapper';
import { Text } from '@propertypal/web-ui/src/typography';
import Link from 'next/link';
import React from 'react';
import { useTheme } from 'styled-components';
import { SiteMapData } from '../Footer';
import { Container, LinkItem, Links, LinkSection, SiteMapFooterSection } from './SiteMapFooter.style';

interface Props {
  siteMapData?: SiteMapData[] | null;
}

const SiteMapFooter: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  if (!props.siteMapData) {
    return null;
  }

  return (
    <SiteMapFooterSection data-testid="site-map-footer">
      <PageWrapper>
        <Container>
          {props.siteMapData.map((item: SiteMapData) => {
            return (
              <LinkSection key={item.parent.label}>
                <Link href={item.parent.url} prefetch={false}>
                  <Text color={theme.textDark} fontSize={14} fontWeight="600">
                    {item.parent.label.toUpperCase()}
                  </Text>
                </Link>
                <Links>
                  {item.children.map((child) => {
                    return (
                      <LinkItem key={child.label}>
                        <Link href={child.url} prefetch={false}>
                          <Text color={theme.textDark} fontSize={14}>
                            {child.label}
                          </Text>
                        </Link>
                      </LinkItem>
                    );
                  })}
                </Links>
              </LinkSection>
            );
          })}
        </Container>
      </PageWrapper>
    </SiteMapFooterSection>
  );
};

export default SiteMapFooter;
