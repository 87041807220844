import { createSlice } from '@reduxjs/toolkit';
import { EnquiryGroupResult } from '../../types/enquiries';
import { RootState } from '../store';

interface State {
  endReached: boolean;
  page: number;
  data?: EnquiryGroupResult;
  loading: boolean;
  error?: Error;
}

export const initialState: State = {
  endReached: true,
  page: 0,
  loading: true,
};

interface SetLoading {
  payload: boolean;
}

interface SetRead {
  payload: number;
}

interface SetData {
  payload: EnquiryGroupResult;
}

interface SetError {
  payload: Error;
}

export const enquiriesSlice = createSlice({
  name: 'enquiries',
  initialState,
  reducers: {
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setData: (state, action: SetData) => {
      state.page = 0;
      state.data = action.payload;

      if (action.payload.totalPages > 1) {
        state.endReached = false;
      }
    },
    setRead: (state, action: SetRead) => {
      if (state.data) {
        state.data.content = state.data.content.map((enquiry) => ({
          ...enquiry,
          read: action.payload === enquiry.id ? true : enquiry.read,
        }));
      }

      return state;
    },
    setMoreData: (state, action: SetData) => {
      if (state.data) {
        state.page += 1;
        state.data = {
          ...action.payload,
          content: [...state.data.content, ...action.payload.content],
        };

        if (action.payload.totalPages === state.page + 1) {
          state.endReached = true;
        }
      }
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    clearEnquiries: (state) => {
      state = { ...initialState };

      return state;
    },
  },
});

export const { clearEnquiries, setLoading, setData, setMoreData, setError, setRead } = enquiriesSlice.actions;

export const selectEnquiries = (state: RootState) => state.enquiries;

export default enquiriesSlice.reducer;
