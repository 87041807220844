import { largeTabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.nav`
  display: none;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 20;
  bottom: 0;
  background: ${(props) => props.theme.background};
  border-top: 1px solid ${(props) => props.theme.backgroundLight};
  border-collapse: collapse;
  padding-bottom: 10px;

  @media screen and (${largeTabletMax}) {
    display: flex;
  }
`;

export const Links = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  list-style: none;
  flex: 1;
  justify-content: center;
  height: 60px;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .itemShortTitle {
    display: none;
  }

  @media (max-width: 600px) {
    .itemTitle {
      display: none;
    }
    .itemShortTitle {
      display: block;
    }
  }
`;

export const Icon = styled.img<{ $active: boolean }>`
  display: block;
  margin: 4px auto;
  color: ${(props) => props.theme.backgroundMid};

  ${(props) =>
    props.$active &&
    `
    filter: invert(39%) sepia(72%) saturate(4668%) hue-rotate(4deg) brightness(96%) contrast(101%);
  `}
`;

export const CustomButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  flex: 1;
  font: inherit;
`;
