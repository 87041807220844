/* eslint-disable no-undef */
import isJSON from '../utils/isJSON';

export const setItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch {
    // silent error
  }
};

export const getItem = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);

    if (typeof item === 'string' && item !== 'undefined') {
      return isJSON(item) ? JSON.parse(item) : item;
    }

    return null;
  } catch {
    return null;
  }
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // silent error
  }
};
