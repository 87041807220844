import { MY_DRAWN_AREA } from '../../constants/search';
import axios, { API_URL } from '../../services/axios';
import { IsoChrone, MapBounds } from '../../types/map';
import { SearchParams } from '../../types/search';
import sanitizeParams from '../../utils/sanitizeParams';
import { getFilters, getNlPFilters } from '../../utils/search';
import { clearNlpData, setSearch, setSearchResultParams } from '../search';
import { AppThunk } from '../store';
import { setPolygons, setMarkers, setError, setLoading } from './map.slice';

type GetMarkers = (imageLocator: string, bounds: MapBounds | undefined, zoom: number, polyId?: number) => AppThunk;

type NewPolygon = (bounds: MapBounds, zoom: number, line: number[], subtract: boolean) => AppThunk;

type NewIsoChrone = (
  location: string,
  time: string,
  mode: string,
  searchParams?: SearchParams,
) => AppThunk<Promise<IsoChrone>>;

export const getMarkers: GetMarkers = (imageLocator, bounds, zoom) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  sanitizeParams(dispatch, getState);

  const { search } = getState();

  try {
    const filtersString = await getFilters(search.filters, dispatch);
    const q = `&q=${encodeURIComponent(search.text)}`;

    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/search-map/properties?${filtersString}${q}&imageLocator=${encodeURIComponent(imageLocator)}`,
        params: {
          format: 'geoJson',
          ...bounds,
          zoom,
        },
      },
      dispatch,
    );

    dispatch(
      setMarkers({
        markers: result.data.markers,
        bounds,
        savable: result.data.savable,
        savedSearch: result.data.savedSearch,
        centre: result.data.centre,
        northWest: result.data.northWest,
        southEast: result.data.southEast,
        url: result.data.url,
        zoom: result.data.zoom,
        params: result.data.params,
        description: result.data.description,
      }),
    );

    // Update search filters just in case the API has merged polyIds
    const { nlpFilters } = getNlPFilters(result.data.params);

    dispatch(
      setSearch({
        text: search.text,
        filters: {
          ...search.filters,
          ...nlpFilters,
        },
      }),
    );

    dispatch(setPolygons(result.data.polygons.coordinates));
    dispatch(setSearchResultParams(result.data.params));
  } catch (err: any) {
    dispatch(setError(err));
  }

  dispatch(setLoading(false));
};

export const newPolygon: NewPolygon = (bounds, zoom, line, subtract) => async (dispatch, getState) => {
  try {
    const { search, map } = getState();

    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/search-map/polygons?format=geoJson`,
        data: {
          params: map.params,
          polyIds: search.filters.polyIds,
          northEast: {
            lat: bounds.north,
            lng: bounds.east,
          },
          southWest: {
            lat: bounds.south,
            lng: bounds.west,
          },
          zoom,
          line,
          subtract,
        },
      },
      dispatch,
    );

    const { nlpFilters } = getNlPFilters(search.nlpData?.params, search.nlpData?.filterItems);
    const filters = {
      ...search.filters,
      ...nlpFilters,
      radius: '',
      expand: '',
      polyIds: [...result.data.polygonIds],
    };

    dispatch(
      setSearch({
        text: MY_DRAWN_AREA,
        filters,
      }),
    );

    dispatch(clearNlpData());
    dispatch(setPolygons(result.data.coordinates));
  } catch (err: any) {
    dispatch(setError(err));
  }
};

export const newIsoChrone: NewIsoChrone = (location, time, mode, searchParams) => async (dispatch) => {
  const result = await axios(
    {
      method: 'POST',
      url: `${API_URL}/search-map/isochrone`,
      data: {
        location,
        time: parseInt(time, 10) * 60,
        mode,
        searchParams,
      },
    },
    dispatch,
  );

  return result.data;
};
