import { Text } from '@propertypal/web-ui/src/typography';
import React from 'react';
import { useTheme } from 'styled-components';
import SocialLinks from '../social-links/SocialLinks';
import { Anchor, Container, LinksContainer } from './CopyrightAndPolicyLinks.style';

const CopyrightAndPolicyLinks = () => {
  const theme = useTheme();

  return (
    <Container data-testid="footer-copyright">
      <Text fontSize={14} color={theme.textDark}>
        &copy; Copyright <b>PropertyPal.com</b> 2007-{new Date().getFullYear()}
      </Text>

      <LinksContainer>
        <Anchor href="/privacy" prefetch={false}>
          <Text fontSize={14} color={theme.textDark}>
            Privacy Policy
          </Text>
        </Anchor>

        <Anchor href="/cookie-policy" prefetch={false}>
          <Text fontSize={14} color={theme.textDark}>
            Cookie Policy
          </Text>
        </Anchor>

        <Anchor href="/terms" prefetch={false}>
          <Text fontSize={14} color={theme.textDark}>
            Terms &amp; Conditions
          </Text>
        </Anchor>

        <Anchor href="/hosting-terms" prefetch={false}>
          <Text fontSize={14} color={theme.textDark}>
            Hosting Terms
          </Text>
        </Anchor>
      </LinksContainer>

      <SocialLinks />
    </Container>
  );
};

export default CopyrightAndPolicyLinks;
