import { createSlice } from '@reduxjs/toolkit';
import { Timeline, TimelineSummary } from '../../types/timeline';
import { RootState } from '../store';

export interface TimelineLoading {
  timeline: boolean;
  summary: boolean;
}

export interface TimelineState {
  summary: TimelineSummary;
  results?: Timeline;
  error?: Error;
  loading: TimelineLoading;
}

interface SetTimeline {
  payload: Timeline;
}

interface SetMoreTimeline {
  payload: Timeline;
}

interface SetTimelineSummary {
  payload: TimelineSummary;
}

interface SetLoading {
  payload: boolean;
}

interface SetError {
  payload: Error;
}

const initialState: TimelineState = {
  summary: {
    totalEnquiries: 0,
    totalFavourites: 0,
    totalNotes: 0,
    totalSearches: 0,
  },
  loading: {
    timeline: true,
    summary: true,
  },
};

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setTimelineLoading: (state, action: SetLoading) => {
      state.loading.timeline = action.payload;
    },
    setSummaryLoading: (state, action: SetLoading) => {
      state.loading.summary = action.payload;
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    setTimeline: (state, action: SetTimeline) => {
      state.results = action.payload;
    },
    setMoreTimeline: (state, action: SetMoreTimeline) => {
      if (state.results) {
        state.results = {
          ...action.payload,
          content: [...state.results.content, ...action.payload.content],
        };
      }
    },
    setTimelineSummary: (state, action: SetTimelineSummary) => {
      state.summary = action.payload;
    },
    clearTimeline: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const {
  clearTimeline,
  setError,
  setTimeline,
  setMoreTimeline,
  setTimelineSummary,
  setTimelineLoading,
  setSummaryLoading,
} = timelineSlice.actions;

export const selectTimeline = (state: RootState) => state.timeline;

export default timelineSlice.reducer;
