import { Dispatch } from 'react';
import { MY_DRAWN_AREA, MY_TRAVEL_TIME_AREA } from '../constants/search';
import { setFilters } from '../reducers/search';
import { RootState } from '../reducers/store';

const sanitizeParams = (dispatch: Dispatch<any>, getState: () => RootState) => {
  const { search, map } = getState();

  // If user or app has removed my drawn area text, polygons also need removed
  if (
    !search.text.includes(MY_DRAWN_AREA) &&
    !search.text.includes(MY_TRAVEL_TIME_AREA) &&
    (map.polygons || search.filters.polyIds.length > 0)
  ) {
    dispatch(
      setFilters({
        ...search.filters,
        polyIds: [],
      }),
    );
  }
};

export default sanitizeParams;
