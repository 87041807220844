/* eslint-disable no-undef */
const parseJwt = (token: string) => {
  if (token) {
    const base64Url = token.split('.')[1];

    if (base64Url) {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    }
  }

  return null;
};

export default parseJwt;
