import facebookIcon from '@propertypal/shared/src/resources/icons/social/facebook.svg?url';
import instagramIcon from '@propertypal/shared/src/resources/icons/social/instagram.svg?url';
import linkedInIcon from '@propertypal/shared/src/resources/icons/social/linkedin.svg?url';
import tikTokIcon from '@propertypal/shared/src/resources/icons/social/tiktok.svg?url';
import twitterIcon from '@propertypal/shared/src/resources/icons/social/twitter.svg?url';
import React from 'react';
import SocialLinksContainer, { IconLink } from './SocialLinks.style';

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <IconLink
        aria-label="Follow PropertyPal on Facebook"
        href="https://www.facebook.com/propertypal"
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebookIcon.src} alt="facebook" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label="Follow PropertyPal on X"
        href="https://www.twitter.com/propertypal"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitterIcon.src} alt="x" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label="Follow PropertyPal on Instagram"
        href="https://www.instagram.com/propertypal"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramIcon.src} alt="instagram" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label="Follow PropertyPal on LinkedIn"
        href="https://www.linkedin.com/company/propertypal-com"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedInIcon.src} alt="linkedin" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label="Follow PropertyPal on tiktok"
        href="https://www.tiktok.com/@propertypal"
        target="_blank"
        rel="noreferrer"
      >
        <img src={tikTokIcon.src} alt="tiktok" loading="lazy" />
      </IconLink>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
