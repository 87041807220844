import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { debounce } from '../../hooks/useDebounce';
import axios, { API_URL } from '../../services/axios';
import { getItem, setItem } from '../../services/local.storage';
import { PropertyStyles, PropertyStyle } from '../../types/propertyStyles';

let propertyStyles: PropertyStyles | null = null;

const DEBOUNCE_TIME = process?.env?.JEST_WORKER_ID ? 0 : 2000;

export const STYLES_KEY = 'propertyStyles';

const ONE_DAY = 60000 * 60 * 24;

export const clearPropertyStyles = () => {
  propertyStyles = null;
};

const fetchPropertyStyles = async (dispatch: Dispatch) => {
  try {
    const result = await axios<PropertyStyles>(
      {
        method: 'GET',
        url: `${API_URL}/property-styles`,
      },
      dispatch,
    );

    propertyStyles = result.data;

    await setItem(STYLES_KEY, {
      timestamp: new Date().getTime(),
      data: propertyStyles,
    });

    return result.data;
  } catch {
    return null;
  }
};

const debouncedPropertyStyles = debounce(fetchPropertyStyles, DEBOUNCE_TIME, {
  leading: true,
  trailing: false,
});

export const getPropertyStyles = async (
  category: string,
  saleType: string,
  dispatch: Dispatch,
): Promise<PropertyStyle[] | null> => {
  if (!propertyStyles) {
    const cachedStyles = getItem<any>(STYLES_KEY);

    if (cachedStyles && new Date().getTime() - cachedStyles.timestamp < ONE_DAY) {
      propertyStyles = cachedStyles.data;
    } else {
      await debouncedPropertyStyles(dispatch);
    }
  }

  if (!propertyStyles) {
    return null;
  }

  let cat = category.toUpperCase();
  const st = saleType.toUpperCase();

  if (cat === 'NEWHOMES') cat = 'NEW_HOMES';
  if (cat === 'RESIDENTIALAUCTIONS') cat = 'AUCTIONS';
  if (cat === 'COMMERCIALAUCTIONS') cat = 'AUCTIONS';

  if (propertyStyles && propertyStyles[cat] && propertyStyles[cat][st] && propertyStyles[cat][st].length > 0) {
    return propertyStyles[cat][st];
  }

  return null;
};

export const usePropertyStyles = (category: string, saleType: string) => {
  const [styles, setStyles] = useState<PropertyStyle[] | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getPropertyStyles(category, saleType, dispatch).then((result) => {
      setStyles(result);
    });
  }, [category, saleType]);

  return styles;
};
