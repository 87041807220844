import axios, { API_URL } from '../../services/axios';
import { SocialFavourite } from '../../types/favourite';
import { updatePropertyFavourite } from '../properties';
import { AppThunk } from '../store';
import {
  addFavourite,
  removeFavourite,
  setError,
  setFavourites,
  setLoading,
  setMoreFavourites,
} from './favourites.slice';

type GetSocialFavs = (view: string, imageLocator: string) => AppThunk;
type SaveFavourite = (propertyId: number) => AppThunk<Promise<boolean>>;
type DeleteFavourite = (propertyId: number) => AppThunk<Promise<boolean>>;

const LIMIT = 10;

export const getFavourites: GetSocialFavs = (view, imageLocator) => async (dispatch) => {
  try {
    dispatch(
      setLoading({
        view,
        loading: true,
      }),
    );

    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group`,
        params: {
          offset: 0,
          limit: LIMIT,
          view,
          type: 'FAVOURITE',
          imageLocator,
        },
      },
      dispatch,
    );

    dispatch(
      setFavourites({
        view,
        data: result.data,
      }),
    );
  } catch (error: any) {
    dispatch(
      setError({
        view,
        error,
      }),
    );
  }
};

export const getMoreFavourites: GetSocialFavs = (view, imageLocator) => async (dispatch, getState) => {
  const { favourites } = getState();

  try {
    dispatch(
      setLoading({
        view,
        loading: true,
      }),
    );

    const offset = favourites.results[view].data?.content.length;
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group`,
        params: {
          offset,
          limit: LIMIT,
          view,
          type: 'FAVOURITE',
          imageLocator,
        },
      },
      dispatch,
    );

    dispatch(
      setMoreFavourites({
        view,
        data: result.data,
      }),
    );
  } catch (error: any) {
    dispatch(
      setError({
        error,
        view,
      }),
    );
  }
};

export const saveFavourite: SaveFavourite = (propertyId: number) => async (dispatch) => {
  try {
    dispatch(
      updatePropertyFavourite({
        propertyId,
        favourited: true,
      }),
    );

    const result = await axios<SocialFavourite>(
      {
        method: 'PUT',
        url: `${API_URL}/social-group/${propertyId}?TYPE=FAVOURITE`,
      },
      dispatch,
    );

    dispatch(addFavourite({ favourite: result.data, propertyId }));

    return true;
  } catch (err: any) {
    return false;
  }
};

export const deleteFavourite: DeleteFavourite = (propertyId: number) => async (dispatch) => {
  try {
    await axios<any>(
      {
        method: 'DELETE',
        url: `${API_URL}/favourite/${propertyId}`,
      },
      dispatch,
    );

    await Promise.all([
      await dispatch(removeFavourite({ propertyId })),
      await dispatch(
        updatePropertyFavourite({
          propertyId,
          favourited: false,
        }),
      ),
    ]);

    return true;
  } catch (err: any) {
    return false;
  }
};
