const capitalize = (input: string) => {
  return (
    input &&
    input
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  );
};
export default capitalize;
