import axios, { API_URL } from '../../services/axios';
import { EnquiryMessages } from '../../types/enquiries';
import { AppThunk } from '../store';
import { setData, setMoreData, setLoading, setError, setRead } from './enquiries.slice';

const PAGE_SIZE = 10;

type DefaultThunk = (imageLocator: string) => AppThunk;

type NewEnquiry = (
  params: EnquiryParams,
  imageLocator: string,
) => AppThunk<Promise<{ success: boolean; errors?: any; leadId?: number }>>;

type NewLead = (id: number, content: string, imageLocator: string) => AppThunk<Promise<EnquiryMessages | null>>;

type GetEnquiry = (propertyId: number, imageLocator: string) => AppThunk<Promise<EnquiryMessages | null>>;

interface EnquiryCustomData {
  'Property Type': string;
  'Approximate Budget Range': string;
  'Are you approved for a mortgage?': string;
  'Do you have a property to sell?': string;
}

interface EnquiryParams {
  propertyId: number;
  propertyType: string;
  contact: string;
  name: string;
  email: string;
  tel: string;
  comments: string;
  recaptcha?: string;
  customData?: EnquiryCustomData;
}

export const getEnquiries: DefaultThunk = (imageLocator) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/lead?page=0&pageSize=${PAGE_SIZE}&imageLocator=${encodeURIComponent(imageLocator)}`,
      },
      dispatch,
    );

    dispatch(setData(result.data));
  } catch (err: any) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMoreEnquiries: DefaultThunk = (imageLocator) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const { enquiries } = getState();

  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/lead?page=${enquiries.page + 1}&pageSize=${PAGE_SIZE}&imageLocator=${encodeURIComponent(
          imageLocator,
        )}`,
      },
      dispatch,
    );

    dispatch(setMoreData(result.data));
  } catch (err: any) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const newEnquiryRecaptcha: NewEnquiry = (params, imageLocator) => async (dispatch, getState) => {
  const { user } = getState();
  const { propertyId, propertyType, ...data } = params;

  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/${propertyType}/${propertyId}/enquiry-with-recaptcha`,
        data,
      },
      dispatch,
    );

    if (user.loggedIn) {
      dispatch(getEnquiries(imageLocator));
    }

    return { success: true, leadId: result.data.leadIds[0] };
  } catch (err: any) {
    return { success: false, errors: err?.data?.errors };
  }
};

export const newEnquiry: NewEnquiry = (params, imageLocator) => async (dispatch, getState) => {
  const { user } = getState();
  const { propertyId, propertyType, ...data } = params;

  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/${propertyType}/${propertyId}/enquiry`,
        data,
      },
      dispatch,
    );

    if (user.loggedIn) {
      dispatch(getEnquiries(imageLocator));
    }

    return { success: true, leadId: result.data.leadIds[0] };
  } catch (err: any) {
    return { success: false, errors: err?.data?.errors };
  }
};

export const getEnquiry: GetEnquiry = (leadId, imageLocator) => async (dispatch) => {
  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/lead/${leadId}?imageLocator=${encodeURIComponent(imageLocator)}&markAsRead=true`,
      },
      dispatch,
    );

    dispatch(setRead(leadId));

    return result?.data;
  } catch {
    return null;
  }
};

export const newLeadMessage: NewLead = (leadId, content, imageLocator) => async (dispatch) => {
  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/lead/${leadId}/message?imageLocator=${encodeURIComponent(imageLocator)}`,
        data: {
          content,
        },
      },
      dispatch,
    );

    return result?.data;
  } catch {
    return null;
  }
};
