import axios, { API_URL } from '../../services/axios';
import { Note, SocialNote } from '../../types/notes';
import { AppThunk } from '../store';
import { setAvatarUrl } from '../user';
import { setData, setMoreData, setLoading, setError, removeSocialNote, addSocialNote } from './notes.slice';

type DefaultThunk = (imageLocator: string) => AppThunk;
type DeleteNote = (id: number, hardDelete: boolean) => AppThunk<Promise<boolean>>;
type AddNote = (id: number, note: string, imageLocator: string) => AppThunk<Promise<boolean>>;
type GetNote = (id: number) => AppThunk<Promise<SocialNote>>;

const LIMIT = 10;

export const getNotes: DefaultThunk = (imageLocator) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group?offset=0&limit=${LIMIT}&view=FULL&type=NOTE&imageLocator=${encodeURIComponent(
          imageLocator,
        )}`,
      },
      dispatch,
    );

    dispatch(setData(result.data));
  } catch (err: any) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMoreNotes: DefaultThunk = (imageLocator) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const { notes } = getState();

  try {
    const offset = `offset=${notes.data?.content.length}`;
    const imageLocatorQuery = `&imageLocator=${encodeURIComponent(imageLocator)}`;
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group?${offset}&limit=${LIMIT}&view=FULL&type=NOTE${imageLocatorQuery}`,
      },
      dispatch,
    );

    dispatch(setMoreData(result.data));
  } catch (err: any) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteNote: DeleteNote = (id, hardDelete) => async (dispatch) => {
  try {
    await axios(
      {
        method: 'DELETE',
        url: `${API_URL}/note/${id}`,
      },
      dispatch,
    );

    dispatch(removeSocialNote({ id, hardDelete }));

    return true;
  } catch {
    return false;
  }
};

export const saveNote: AddNote = (id, note, imageLocator) => async (dispatch) => {
  try {
    const result = await axios(
      {
        method: 'PUT',
        url: `${API_URL}/social-group/${id}?type=NOTE&imageLocator=${encodeURIComponent(imageLocator)}`,
        data: { content: note },
      },
      dispatch,
    );

    await dispatch(addSocialNote({ data: result.data, id }));

    // use the response to set the avatar in the profile if nt loaded at this point.
    const noteIveAdded = result.data.notes.filter((n: Note) => n.selfAuthored);
    if (noteIveAdded.length > 0) await dispatch(setAvatarUrl(noteIveAdded[0].authorAvatar));

    return true;
  } catch (err: any) {
    return false;
  }
};

export const getNote: GetNote = (id) => async (dispatch) => {
  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group/${id}?type=NOTE`,
      },
      dispatch,
    );

    return result?.data;
  } catch (err: any) {
    return false;
  }
};
