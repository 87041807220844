import axios, { API_URL } from '../../services/axios';
import { Pal } from '../../types/pals';
import { AppThunk } from '../store';
import { setPals, setError, setLoading } from './pals.slice';

type DefaultThunk = () => AppThunk;

type AddPal = (email: string) => AppThunk<Promise<boolean>>;

type AcceptPal = (id: number, email: string) => AppThunk<Promise<boolean>>;

type PalInvite = (inviteId: string, inviteToken: string, inviteById: string) => AppThunk<Promise<boolean>>;

type RemovePal = (id: number, email: string) => AppThunk<Promise<boolean>>;

export const getPals: DefaultThunk = () => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/friend`,
      },
      dispatch,
    );

    dispatch(setPals(result.data.friends));
  } catch (err: any) {
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addPal: AddPal = (email) => async (dispatch, getState) => {
  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/friend`,
        data: { email },
      },
      dispatch,
    );

    const { pals } = getState();

    if (pals.data) {
      const data = [...pals.data, result.data];
      dispatch(setPals(data));
    } else {
      const data = [result.data];
      dispatch(setPals(data));
    }

    return true;
  } catch (err: any) {
    return false;
  }
};

export const acceptPal: AcceptPal = (id, email) => async (dispatch, getState) => {
  try {
    const res = await axios<Pal>(
      {
        method: 'PUT',
        url: `${API_URL}/friend/${id}`,
        data: { email },
      },
      dispatch,
    );

    const { pals } = getState();

    if (pals.data && res.data) {
      const data = pals.data.map((pal) => {
        if (pal.id === id) {
          return {
            ...pal,
            status: 'VERIFIED',
          };
        }

        return pal;
      });

      dispatch(setPals(data));
    }

    return true;
  } catch (err: any) {
    return false;
  }
};

export const acceptPalInvite: PalInvite = (inviteId, inviteToken, inviteById) => async (dispatch) => {
  try {
    const query = `inviteId=${inviteId}&inviteToken=${inviteToken}&inviteById=${inviteById}`;

    await axios(
      {
        method: 'GET',
        url: `${API_URL}/pals/accept-friend?${query}`,
      },
      dispatch,
    );

    await dispatch(getPals());

    return true;
  } catch (err: any) {
    return false;
  }
};

export const acceptInvite = async (inviteId: string, inviteToken: string) => {
  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/pals/accept-invite?inviteId=${inviteId}&inviteToken=${inviteToken}`,
      },
      undefined,
    );

    return result.data.email;
  } catch (err: any) {
    return '';
  }
};

export const removePal: RemovePal = (id, email) => async (dispatch, getState) => {
  try {
    await axios(
      {
        method: 'DELETE',
        url: `${API_URL}/friend/${id}`,
        data: { email },
      },
      dispatch,
    );

    const { pals } = getState();

    if (pals.data) {
      const data = pals.data.filter((pal) => pal.email !== email);

      dispatch(setPals(data));
    }

    return true;
  } catch (err: any) {
    return false;
  }
};

export const rejectPalInvite: PalInvite = (inviteId, inviteToken, inviteById) => async (dispatch) => {
  try {
    const query = `inviteId=${inviteId}&inviteToken=${inviteToken}&inviteById=${inviteById}`;

    await axios(
      {
        method: 'GET',
        url: `${API_URL}/pals/reject-friend?${query}`,
      },
      dispatch,
    );

    await dispatch(getPals());

    return true;
  } catch (err: any) {
    return false;
  }
};
