/* eslint-disable no-undef */
declare global {
  interface Navigator {
    product: string;
  }
}

const isReactNative = () => {
  return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
};

export default isReactNative;
