/* eslint-disable no-undef */

const setEmailHashes = (emailHashes?: string[]) => {
  if (
    emailHashes &&
    typeof window !== 'undefined' &&
    window.ats &&
    typeof window.ats.setAdditionalData === 'function'
  ) {
    window.ats.setAdditionalData({ type: 'emailHashes', id: emailHashes });
  }
};

export default setEmailHashes;
