import { createSlice } from '@reduxjs/toolkit';
import { SocialNote, SocialNoteResult } from '../../types/notes';
import { RootState } from '../store';

export interface SocialNoteState {
  data?: SocialNoteResult;
  loading: boolean;
  error?: Error;
}

const initialState: SocialNoteState = {
  loading: true,
};

interface SetLoading {
  payload: boolean;
}

interface SetData {
  payload: SocialNoteResult;
}

interface SetError {
  payload: Error;
}

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setData: (state, action: SetData) => {
      state.data = action.payload;
    },
    setMoreData: (state, action: SetData) => {
      if (state.data) {
        state.data = {
          ...action.payload,
          content: [...state.data.content, ...action.payload.content],
        };
      }
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    removeSocialNote: (state, action) => {
      const { id, hardDelete } = action.payload;

      if (state.data) {
        const { content } = state.data;
        // find the item we want to remove.
        const foundIndex = content.map((contentItem) => contentItem.property.id).indexOf(id);

        const foundNote: SocialNote = state.data.content[foundIndex];
        // direct replace in the array.
        state.data.content[foundIndex] = foundNote;

        // if it doesnt belong to a mate, empty notes array
        if (!foundNote.friendOwned) {
          foundNote.notes = [];
          state.data.content[foundIndex] = foundNote;
        } else {
          // is friend owned
          // so just remove my entry from the loop.
          foundNote.notes = foundNote.notes.filter((note) => !note.selfAuthored);
          foundNote.selfOwned = false;
          state.data.content[foundIndex] = foundNote;
        }

        if (hardDelete && !foundNote.friendOwned) {
          state.data.content.splice(foundIndex, 1);
        }
        if (hardDelete) {
          state.data.totalGroups -= 1;
          state.data.totalPersonalGroups -= 1;
        }
      }
    },
    addSocialNote: (state, action) => {
      const { data, id } = action.payload;

      if (state.data) {
        const { content } = state.data;
        // find the item we want to remove.
        const foundIndex = content.findIndex((contentItem) => contentItem.property.id === id);
        if (foundIndex > -1) {
          // replace
          state.data.content[foundIndex] = data;
        } else {
          state.data.content.unshift(data);
          state.data.totalGroups += 1;
          state.data.totalPersonalGroups += 1;
        }
      }
    },
    clearNotes: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const { removeSocialNote, addSocialNote, setLoading, setData, setMoreData, setError, clearNotes } =
  notesSlice.actions;

export const selectNotes = (state: RootState) => state.notes;

export default notesSlice.reducer;
