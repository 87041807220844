import { HeatingType, SearchFormNlpResult } from '../../types/search';

export interface Filters {
  minBeds?: string;
  maxBeds?: string;
  minPrice?: string;
  maxPrice?: string;
  radius?: string;
  expand?: string;
  currency?: string;
  category?: string;
  sortOrder?: string;
  includeGone?: boolean;
  includeAgreed?: boolean;
  excludePoa?: boolean;
  excludeBuildingSites?: boolean;
  excludeStudentLettings?: boolean;
  unfurnished?: boolean;
  furnished?: boolean;
  partiallyFurnished?: boolean;
  optionalFurnished?: boolean;
  polyIds?: number[];
  saleType?: string;
  styles?: string[];
  term?: string;
  filter?: Array<{ filterValue: string; description: string }>;
  lat?: number | null;
  lng?: number | null;
  heatingType?: HeatingType[];
  epc?: string;
  includePotentialEpc?: boolean;
  yearBuilt?: string;
  minInternalArea?: string;
  broadband?: string;
  garage?: boolean;
  largeGarage?: boolean;
  garageSize?: string | null;
  tags?: string[] | null;
  excludeTags?: string[] | null;
  coOwnership?: boolean;
  excludeNewHomes?: boolean;
}

interface Result {
  nlpFilters: Filters;
}

/**
 * Converts params object from API into an object that can be saved in search reducer
 */

const getNlPFilters = (
  params?: SearchFormNlpResult['params'],
  filterItems?: SearchFormNlpResult['filterItems'],
): Result => {
  if (!params) {
    return {
      nlpFilters: {},
    };
  }

  const filters: Filters = {};

  if (params.minBeds) filters.minBeds = String(params.minBeds);
  if (params.maxBeds) filters.maxBeds = String(params.maxBeds);
  if (params.minPrice) filters.minPrice = String(params.minPrice);
  if (params.maxPrice) filters.maxPrice = String(params.maxPrice);
  if (params.currency) filters.currency = String(params.currency);
  if (params.radius) filters.radius = String(params.radius);
  if (params.expand) filters.expand = String(params.expand);
  if (params.sort) filters.sortOrder = params.sort;
  if (params.gone) filters.includeGone = true;
  if (params.agreed) filters.includeAgreed = true;
  if (params.excludePoa) filters.excludePoa = true;
  if (params.heatingType?.length) filters.heatingType = [...params.heatingType];
  if (params.epc) filters.epc = params.epc;
  if (params.epcRatingType === 'EITHER') filters.includePotentialEpc = true;
  if (params.minInternalArea) filters.minInternalArea = String(params.minInternalArea);
  if (params.broadband?.length) filters.broadband = params.broadband[0];
  if (params.garage) {
    filters.garage = true;
    filters.largeGarage = false;
  }
  if (params.largeGarage) filters.largeGarage = true;
  if (params.garageSize) filters.garageSize = String(params.garageSize);
  if (params.tags) filters.tags = [...params.tags];
  if (params.excludeTags) filters.excludeTags = [...params.excludeTags];
  if (typeof params.coown === 'boolean') filters.coOwnership = params.coown;
  if (typeof params.excludeNewHomes === 'boolean') filters.excludeNewHomes = params.excludeNewHomes;

  if (params.minYearBuilt || params.maxYearBuilt) {
    filters.yearBuilt = `${params.minYearBuilt || 0}-${params.maxYearBuilt || 0}`;
  }

  if (
    params.category &&
    params.category[0] === 'residential' &&
    ((params.f && params.f.includes('att:20')) || (params.filter && params.filter.includes('att:20')))
  ) {
    filters.category = 'student';
    filters.saleType = 'rent';
  } else if (params.category) {
    filters.category = params.category[0];
    filters.saleType = params.saleType && params.saleType[0] ? params.saleType[0] : 'sale';
  }

  if (params.styleGroup) {
    filters.styles = [...params.styleGroup];
  }

  if (params.ft) {
    filters.furnished = params.ft.includes('fullyFurnished');
    filters.unfurnished = params.ft.includes('unfurnished');
    filters.partiallyFurnished = params.ft.includes('partlyFurnished');
    filters.optionalFurnished = params.ft.includes('optional');
  }

  if (filterItems) {
    filters.filter = filterItems.filter((item) => {
      return item.description !== 'Student';
    });
  }

  if (params.polygonId) {
    filters.polyIds = [...params.polygonId];
  }

  if (params.rlat && params.rlng) {
    filters.lat = params.rlat;
    filters.lng = params.rlng;
  }

  return {
    nlpFilters: filters,
  };
};

export default getNlPFilters;
