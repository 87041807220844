let locationHeader = '';

export const setLocationHeader = (value: string) => {
  if (typeof window !== 'undefined') {
    locationHeader = value;
  }
};

const getUserAgent = (ctx: any): { [key: string]: string } => {
  if (locationHeader) {
    return { 'x-ipcountry': locationHeader };
  }

  if (ctx && ctx.req && ctx.req.headers['cf-ipcountry']) {
    return { 'x-ipcountry': ctx.req.headers['cf-ipcountry'] };
  }

  return {};
};

export default getUserAgent;
