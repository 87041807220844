import { tabletMax, laptopMediumMax, largeTabletMin } from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';

export const FooterContainer = styled.footer<{ pb?: number }>`
  border-top: 1px solid ${(props) => props.theme.backgroundLight};
  border-bottom: 1px solid ${(props) => props.theme.backgroundLight};
  padding-top: 30px;
  padding-bottom: 90px;

  ${({ pb }) =>
    pb != null
      ? css`
          @media screen and (${largeTabletMin}) {
            padding-bottom: ${pb}px;
          }
        `
      : ``}
`;

export const FooterInner = styled.div`
  gap: min(96px, 3vw);
  display: flex;
  justify-content: space-between;
  max-width: 2000px;
  margin: 0 auto;

  @media screen and (${laptopMediumMax}) {
    flex-wrap: wrap;
    gap: initial;
  }

  @media screen and (${tabletMax}) {
    display: block;
  }
`;

export const Links = styled.div`
  display: flex;
  flex: 1;

  @media (${tabletMax}) {
    flex-direction: column;
  }
`;

export const FaqFooterLinks = styled(Links)`
  @media (min-width: 769px) {
    max-width: 560px;
  }
`;

export const LinksColumn = styled.div`
  flex-basis: 33.3%;
  padding-right: 5px;

  h6 {
    color: ${(props) => props.theme.textDark};
    font-size: 14px;
    margin-bottom: 20px;
  }

  p {
    max-width: 220px;
    font-size: 14px;
    margin: 10px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
    }

    li > button,
    a {
      text-align: left;
      font-size: 14px;
      color: ${(props) => props.theme.textDark};

      &:hover {
        color: ${(props) => props.theme.backgroundMid};
      }
    }
  }

  @media screen and (${laptopMediumMax}) {
    flex-basis: 100%;
  }

  @media screen and (${tabletMax}) {
    margin-bottom: 30px;
  }
`;

export const ButtonLink = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  padding: 12px;
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  font-size: 16px;
`;

export const Logo = styled.img`
  width: 136px;
  height: 49px;
  cursor: pointer;
`;
