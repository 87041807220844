import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { HAS_BEDROOMS_FILTER } from '../../constants/filterOptions';
import { SearchState } from '../../reducers/search';
import arrayIntersection from '../intersection';
import { getPropertyStyles } from '../property/getPropertyStyles';

/**
 * Converts search state filters into a query string to be used with the api
 */

const getFilters = async (filters: SearchState['filters'], dispatch: Dispatch, includeDefaults: boolean = true) => {
  const parts: string[] = [];
  const propertyStyles = await getPropertyStyles(filters.category, filters.saleType, dispatch);

  if (includeDefaults) {
    if (filters.category && filters.saleType) {
      parts.push(`category=${filters.category}`);
      parts.push(`saleType=${filters.saleType}`);
    }

    if (filters.currency) parts.push(`currency=${filters.currency}`);
  }

  if (filters.maxPrice) parts.push(`maxPrice=${filters.maxPrice}`);
  if (filters.minPrice) parts.push(`minPrice=${filters.minPrice}`);
  if (filters.sortOrder) parts.push(`sort=${filters.sortOrder}`);
  if (filters.term) parts.push(`term=${filters.term}`);
  if (filters.epc) parts.push(`epc=${filters.epc}`);
  if (filters.epc && filters.includePotentialEpc) parts.push('epcRatingType=EITHER');
  if (filters.minInternalArea) parts.push(`minInternalArea=${filters.minInternalArea}`);
  if (filters.broadband) parts.push(`broadband=${filters.broadband}`);

  if (filters.yearBuilt && filters.category !== 'newhomes') {
    const [minYearBuilt, maxYearBuilt] = filters.yearBuilt.split('-');

    if (minYearBuilt !== '0') parts.push(`minYearBuilt=${minYearBuilt}`);
    if (maxYearBuilt !== '0') parts.push(`maxYearBuilt=${maxYearBuilt}`);
  }

  if (filters.filter.length > 0) {
    filters.filter.forEach((filterItem) => {
      parts.push(`f=${filterItem.filterValue}`);
    });
  }

  if (filters.heatingType && filters.heatingType.length > 0) {
    filters.heatingType.forEach((ht) => {
      parts.push(`heatingType=${ht}`);
    });
  }

  if (filters.tags && filters.tags.length > 0) {
    filters.tags.forEach((ht) => {
      parts.push(`tags=${ht}`);
    });
  }

  if (filters.excludeTags && filters.excludeTags.length > 0) {
    filters.excludeTags.forEach((ht) => {
      parts.push(`excludeTags=${ht}`);
    });
  }

  if (HAS_BEDROOMS_FILTER.includes(filters.category)) {
    if (filters.minBeds) parts.push(`minBeds=${filters.minBeds}`);
    if (filters.maxBeds) parts.push(`maxBeds=${filters.maxBeds}`);
  }

  if (filters.expand) {
    parts.push(`expand=${filters.expand}`);
  }

  if (filters.radius) {
    parts.push(`radius=${filters.radius}`);
  }

  if (filters.lat && filters.lng) {
    parts.push(`lat=${filters.lat}`);
    parts.push(`lng=${filters.lng}`);
  }

  if (filters.includeAgreed) parts.push('agreed=true');
  if (filters.includeGone) parts.push('gone=true');
  if (filters.excludePoa) parts.push('excludePoa=true');
  if (filters.garage) parts.push('garage=true');
  if (filters.largeGarage) parts.push('largeGarage=true');
  if (filters.garageSize) parts.push(`garageSize=${filters.garageSize}`);

  if (
    ((filters.category === 'residential' && filters.saleType === 'sale') || filters.category === 'newhomes') &&
    filters.coOwnership
  ) {
    parts.push('coown=true');
  }

  if (filters.category === 'residential' && filters.saleType === 'sale' && filters.excludeNewHomes) {
    parts.push('excludeNewHomes=true');
  }

  if (filters.category === 'residential' && filters.saleType === 'rent' && filters.excludeStudentLettings) {
    parts.push('exclude=20');
  }

  if (propertyStyles) {
    // Strip any property styles not related to selected category
    const intersectionStyles = propertyStyles.map((pStyle) => pStyle.key);
    const styleGroups = arrayIntersection(filters.styles, intersectionStyles);

    styleGroups.forEach((stygrp) => {
      parts.push(`stygrp=${stygrp}`);
    });
  }

  if (['student', 'residential'].includes(filters.category) && filters.saleType === 'rent') {
    if (filters.unfurnished) parts.push('ft=unfurnished');
    if (filters.furnished) parts.push('ft=fullyFurnished');
    if (filters.partiallyFurnished) parts.push('ft=partlyFurnished');
    if (filters.optionalFurnished) parts.push('ft=optional');
  }

  if (filters.polyIds.length) {
    filters.polyIds.forEach((id) => {
      parts.push(`polyId=${id}`);
    });
  }

  if (parts.length > 0) {
    return parts.join('&');
  }

  return '';
};

export const useFilters = (filters: SearchState['filters'], includeDefaults: boolean = true) => {
  const [queryString, setQueryString] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getFilters(filters, dispatch, includeDefaults).then((filterString) => {
      setQueryString(filterString);
    });
  }, [JSON.stringify(filters)]);

  return queryString;
};

export default getFilters;
