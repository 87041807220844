import React, { FunctionComponent } from 'react';

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  icon: any;
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  testID?: string;
}

const FontAwesomeIcon: FunctionComponent<Props> = ({
  className,
  icon,
  size,
  style,
  color,
  testID,
  title,
  ...props
}) => {
  return (
    <svg
      className={className}
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
      style={{
        display: 'inline-block',
        overflow: 'visible',
        fontSize: size,
        ...style,
        height: '1em',
      }}
      color={color}
      data-testid={testID}
      {...props}
    >
      {title && <title>{title}</title>}
      <path fill="currentColor" d={icon.icon[4]} />
    </svg>
  );
};

export default FontAwesomeIcon;
