import { createSlice } from '@reduxjs/toolkit';
import { SocialFavourite, SocialFavouriteResult } from '../../types/favourite';
import { RootState } from '../store';

export interface FavouritesResult {
  error?: Error;
  data?: SocialFavouriteResult;
  loading: boolean;
}

export interface FavouritesState {
  stats: {
    totalResults: number;
    totalGroups: number;
    totalPersonalGroups: number;
    totalOtherGroups: number;
  };
  results: {
    PERSONAL: FavouritesResult;
    FULL: FavouritesResult;
    FRIENDS: FavouritesResult;
    [key: string]: FavouritesResult;
  };
}

interface SetFavourites {
  payload: {
    data: SocialFavouriteResult;
    view: string;
  };
}

interface SetMoreFavourites {
  payload: {
    data: SocialFavouriteResult;
    view: string;
  };
}

interface SetLoading {
  payload: {
    loading: boolean;
    view: string;
  };
}

interface SetError {
  payload: {
    error: Error;
    view: string;
  };
}

interface AddFavourite {
  payload: {
    propertyId: number;
    favourite: SocialFavourite;
  };
}

interface RemoveFavourite {
  payload: {
    propertyId: number;
  };
}

const initialState: FavouritesState = {
  stats: {
    totalResults: 0,
    totalGroups: 0,
    totalPersonalGroups: 0,
    totalOtherGroups: 0,
  },
  results: {
    PERSONAL: {
      loading: false,
    },
    FULL: {
      loading: false,
    },
    FRIENDS: {
      loading: false,
    },
  },
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    setLoading: (state, action: SetLoading) => {
      const { loading, view } = action.payload;
      state.results[view].loading = loading;
      state.results[view].error = undefined;

      return state;
    },
    setError: (state, action: SetError) => {
      const { error, view } = action.payload;
      state.results[view].error = error;

      return state;
    },
    setFavourites: (state, action: SetFavourites) => {
      const { view, data } = action.payload;

      state.stats.totalResults = data.totalResults;
      state.stats.totalGroups = data.totalGroups;
      state.stats.totalPersonalGroups = data.totalPersonalGroups;
      state.stats.totalOtherGroups = data.totalOtherGroups;

      state.results[view].data = data;
      state.results[view].loading = false;
    },
    setMoreFavourites: (state, action: SetMoreFavourites) => {
      const view = state.results[action.payload.view];

      if (view.data) {
        view.data = {
          ...action.payload.data,
          content: [...view.data.content, ...action.payload.data.content],
        };
        view.loading = false;
      }
    },
    addFavourite: (state, action: AddFavourite) => {
      const { propertyId, favourite } = action.payload;

      ['PERSONAL', 'FULL'].forEach((key) => {
        const data = state.results[key].data;

        if (data) {
          const foundIndex = data.content.findIndex((contentItem) => {
            return contentItem.property.id === propertyId;
          });

          // not there, go ahead and push directly (onto the top of array.)
          if (foundIndex !== null && foundIndex > -1) {
            data.content[foundIndex] = favourite;
          } else {
            data.content.unshift(favourite);
          }
        }
      });

      if (state.results.PERSONAL.data) {
        // bump by one.
        state.stats.totalGroups += 1;
        state.stats.totalPersonalGroups += 1;
      }

      return state;
    },
    removeFavourite: (state, action: RemoveFavourite) => {
      const { propertyId } = action.payload;

      Object.keys(state.results).forEach((key) => {
        const data = state.results[key].data;

        if (data) {
          // find the item we want to remove.
          const foundIndex = data.content.findIndex((contentItem) => contentItem.property.id === propertyId);
          const foundFavourite: SocialFavourite = data.content[foundIndex];

          // no longer belongs to us, and no longer a favourite.
          foundFavourite.selfOwned = false;
          foundFavourite.favourite = false;
          foundFavourite.users = foundFavourite.users.filter((user) => !user.self);

          // direct replace in the array.
          data.content[foundIndex] = foundFavourite;

          // if it doesn't belong to a mate,
          // or, if you happen to be looking at your own favs.
          // dump altogether out of the array.
          if (!foundFavourite.friendOwned || key === 'PERSONAL') {
            data.content.splice(foundIndex, 1);
          }
        }
      });

      state.stats.totalGroups -= 1;
      state.stats.totalPersonalGroups -= 1;

      return state;
    },
    clearFavourites: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const {
  addFavourite,
  clearFavourites,
  removeFavourite,
  setError,
  setFavourites,
  setMoreFavourites,
  setLoading,
} = favouritesSlice.actions;

export const selectFavourites = (state: RootState) => state.favourites;

export default favouritesSlice.reducer;
