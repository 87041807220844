import createStore from '@propertypal/shared/src/reducers/createStore';
import logging from '@propertypal/shared/src/services/logging';
import { setLocationHeader } from '@propertypal/shared/src/utils/getUserAgent';
import router from 'next/router';
import React from 'react';
import { Provider } from 'react-redux';
import 'what-input';
import '../styles/globals.css';
import appConfig from '../../../../app.config.json';
import Layout from '../components/layout';

if (process.env.NEXT_PHASE !== 'phase-production-build' && appConfig.bugsnagApiKey && appConfig.env !== 'Local') {
  import('../services/bugsnag');
}

class AppWrapper extends React.Component {
  store = null;

  idx = 0;

  constructor(props) {
    super(props);

    this.store = createStore(this.props.pageProps.initialState);

    setLocationHeader(this.props.pageProps.cfIpCountry);
  }

  componentDidMount() {
    router.events.on('routeChangeComplete', this.handleRouteChange);
  }

  shouldComponentUpdate(nextProps) {
    // _app.js updates whenever the users changes page. Here we update the store with any data from getServersideProps
    // This is called before the update, so that the data is available on render without the need for a state update
    // This primarily aids scroll restoration when navigating back through your history
    // eslint-disable-next-line
    if ((!nextProps.router._shallow || this.idx > nextProps.router._idx) && !!nextProps.pageProps.initialState) {
      this.store = createStore(nextProps.pageProps.initialState);
    }

    // eslint-disable-next-line
    this.idx = nextProps.router._idx;

    return true;
  }

  componentWillUnmount() {
    router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  handleRouteChange = (url) => {
    // eslint-disable-next-line
    if (!this.props.router._shallow) {
      logging.logCrumb({ message: 'Navigation', metaData: { url } });
    }

    const html = document.querySelector('html');
    html.setAttribute('data-whatelement', '');
  };

  render() {
    const { Component } = this.props;
    const { emailHashes, ...pageProps } = this.props.pageProps;

    return (
      <Provider store={this.store}>
        <Layout {...pageProps} emailHashes={emailHashes}>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    );
  }
}

const MyApp = (props) => {
  return <AppWrapper {...props} />;
};

export default MyApp;
