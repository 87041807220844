import React from 'react';
import SkipLink from './SkipToContent.style';

const SkipToContent = () => {
  const onClick = () => {
    const main = document.getElementById('main');
    main?.focus();
    main?.scrollIntoView();
  };

  return <SkipLink onClick={onClick}>Skip to content</SkipLink>;
};

export default SkipToContent;
