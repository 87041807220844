const isJSON = (value: string) => {
  try {
    JSON.parse(value);
  } catch {
    return false;
  }

  return true;
};

export default isJSON;
