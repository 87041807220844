import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.section`
  margin: 15px 0 0;
  display: flex;
  align-items: flex-start;
  gap: 15px;

  @media (${laptopMediumMax}) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const Anchor = styled(Link)`
  cursor: pointer;
  padding: 0 10px;
  border-left: 1px solid ${(props) => props.theme.backgroundLight};

  &:hover p {
    color: ${(props) => props.theme.backgroundLight};
  }

  @media (${laptopMediumMax}) {
    padding: 0 10px;

    &:first-child {
      border-left: none;
      padding-left: 0;
    }
  }
`;
