import styled from 'styled-components';

const ANCHOR_WIDTH = 8;

export const Container = styled.div<{ anchor: string; colour?: string; w: number }>`
  pointer-events: none;
  position: absolute;
  width: ${({ w }) => w || 160}px;
  padding: 10px;
  background-color: ${({ colour }) => colour || 'rgba(0, 0, 0, 0.75)'};
  border-radius: 8px;
  z-index: 999;

  &:after {
    content: '';
    border-left: ${ANCHOR_WIDTH}px solid transparent;
    border-right: ${ANCHOR_WIDTH}px solid transparent;
    border-top: ${ANCHOR_WIDTH}px solid ${({ colour }) => colour || 'rgba(0, 0, 0, 0.75)'};
    position: absolute;
    bottom: -${ANCHOR_WIDTH}px;
    left: ${(props) => {
      if (props.anchor === 'left') return '10px';
      if (props.anchor === 'right') return '130px';

      return `${(props.w || 160) / 2 - ANCHOR_WIDTH}px`;
    }};
  }
`;

export const Text = styled.p<{ fontSize?: number }>`
  color: white;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || 12}px;
  font-weight: 600;
`;
