import Link from 'next/link';
import styled from 'styled-components';

export interface TextProps {
  color?: string;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  w?: string;
  maxWidth?: number;
  maxWidthPercent?: string;
  textTransform?: string;
  textOverflow?: string;
  overflow?: string;
  cursor?: string;
  display?: string;
  justifyContent?: string;
  textDecoration?: string;
  alignItems?: string;
  gap?: number;
  autoMargin?: boolean;
  fontWeight?: 'bold' | 'normal' | '800' | '600' | '400' | '300';

  fontSize?: number;
  lineHeight?: number;
  align?: 'center' | 'left' | 'right';
}

export const applyProps = (props: TextProps) => {
  let styles = '';
  if (props.autoMargin) styles += `margin: auto;`;
  if (props.color) styles += `color: ${props.color};`;
  if (props.mb) styles += `margin-bottom: ${props.mb}px;`;
  if (props.mt) styles += `margin-top: ${props.mt}px;`;
  if (props.ml) styles += `margin-left: ${props.ml}px;`;
  if (props.mr) styles += `margin-right: ${props.mr}px;`;
  if (props.fontSize) styles += `font-size: ${props.fontSize}px;`;
  if (props.fontWeight) styles += `font-weight: ${props.fontWeight};`;
  if (props.align) styles += `text-align: ${props.align};`;
  if (props.lineHeight) styles += `line-height: ${props.lineHeight}px;`;
  if (props.maxWidth) styles += `max-width: ${props.maxWidth}px;`;
  if (props.maxWidthPercent) styles += `max-width: ${props.maxWidthPercent}%;`;
  if (props.textTransform) styles += `text-transform: ${props.textTransform};`;
  if (props.textOverflow) styles += `text-overflow: ${props.textOverflow};`;
  if (props.overflow) styles += `overflow: ${props.overflow};`;
  if (props.cursor) styles += `cursor: ${props.cursor};`;
  if (props.display) styles += `display: ${props.display};`;
  if (props.w) styles += `width: ${props.w};`;
  if (props.justifyContent) styles += `justify-content: ${props.justifyContent};`;
  if (props.textDecoration) styles += `text-decoration: ${props.textDecoration};`;
  if (props.alignItems) styles += `align-items: ${props.alignItems};`;
  if (props.gap) styles += `gap: ${props.gap}px;`;

  return styles;
};

export const Heading = styled.h1<TextProps>`
  font-size: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.textDark};
  ${applyProps}
`;

export const SubHeading = styled.h2<TextProps>`
  font-size: 24px;
  color: ${(props) => props.theme.textDark};
  ${applyProps}
`;

export const MidHeading = styled.h3<TextProps>`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.textDark};
  ${applyProps}
`;

export const SmallHeading = styled.h4<TextProps>`
  font-size: 14px;
  color: ${(props) => props.theme.primary};
  ${applyProps}
`;

export const LightHeading = styled.h3<TextProps>`
  font-weight: 300;
  ${applyProps}
`;

export const Text = styled.p<TextProps>`
  font-size: 16px;
  color: ${(props) => props.theme.textMid};
  font-weight: normal;
  ${applyProps}
`;

export const LinkText = styled.a<TextProps>`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
  cursor: pointer;
  transition: 300ms all;

  &:hover {
    color: ${(props) => props.theme.backgroundMid};
  }

  ${applyProps}
`;

export const TextLink = styled(Link)<TextProps>`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
  cursor: pointer;
  transition: 300ms all;

  &:hover {
    color: ${(props) => props.theme.backgroundMid};
  }

  ${applyProps}
`;

export const UnorderedList = styled.ul<TextProps>`
  padding-left: 30px;
  ${applyProps}

  li {
    color: ${(props) => props.theme.textDark};
  }
`;

export const ListItem = styled.li`
  font-size: 16px;
  color: ${(props) => props.theme.textMid};
  margin-bottom: 5px;
`;

export const InputLabel = styled.label<TextProps>`
  display: block;
  color: ${(props) => props.theme.primary};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  ${applyProps}
`;

export const Bold = styled.strong<TextProps>`
  font-weight: bold;
  display: inline-block;
  ${applyProps}
`;

export const ItalicText = styled.p<TextProps>`
  font-style: italic;
  font-size: 18px;
  color: ${(props) => props.theme.backgroundMid};
  font-weight: 400;
  ${applyProps}
`;

export const BoldSmallText = styled.h4<TextProps>`
  font-weight: bold;
  font-size: 14px;
  ${applyProps}
`;

export const Table = styled.table`
  text-align: center;
  margin-bottom: 30px;
  & tbody th,
  & tbody td {
    padding: 8px;
  }
  & tbody > tr:nth-child(even) {
    background-color: ${(props) => props.theme.backgroundLighter};
  }
`;
