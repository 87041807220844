import dayjs from 'dayjs';

export const HAS_BEDROOMS_FILTER = ['residential', 'student', 'holidayhomes', 'newhomes', 'residentialAuctions'];

export const HOUSE_STYLES = ['detached', 'semiDetached', 'terraced', 'bungalow', 'houseAndLand'];

export const AUCTION_CATEGORIES = ['residentialAuctions', 'commercialAuctions', 'auctions'];

export const RADIUS_OPTIONS = [
  { label: 'None', value: '' },
  { label: '¼ Mile', value: '0.25' },
  { label: '½ Mile', value: '0.5' },
  { label: '¾ Mile', value: '0.75' },
  { label: '1 Mile', value: '1' },
  { label: '2 Miles', value: '2' },
  { label: '3 Miles', value: '3' },
  { label: '4 Miles', value: '4' },
  { label: '5 Miles', value: '5' },
  { label: '10 Miles', value: '10' },
  { label: '15 Miles', value: '15' },
  { label: '20 Miles', value: '20' },
  { label: '25 Miles', value: '25' },
];

export const EXPAND_OPTIONS = [
  { label: 'None', value: '' },
  { label: '+¼ Mile', value: '0.25' },
  { label: '+½ Mile', value: '0.5' },
  { label: '+¾ Mile', value: '0.75' },
  { label: '+1 Mile', value: '1' },
  { label: '+2 Miles', value: '2' },
  { label: '+3 Miles', value: '3' },
  { label: '+4 Miles', value: '4' },
  { label: '+5 Miles', value: '5' },
  { label: '+10 Miles', value: '10' },
  { label: '+15 Miles', value: '15' },
  { label: '+20 Miles', value: '20' },
  { label: '+25 Miles', value: '25' },
];

export const CATEGORY_OPTIONS = [
  { label: 'Residential For Sale', value: 'residentialForSale' },
  { label: 'Residential For Rent', value: 'residentialForRent' },
  { label: 'Student Rentals', value: 'studentForRent' },
  { label: 'New Homes', value: 'newhomesForSale' },
  { label: 'Holiday Homes', value: 'holidayhomesForRent' },
  { label: 'Residential Auctions', value: 'residentialAuctionsForSale' },
  { label: 'Commercial Auctions', value: 'commercialAuctionsForSale' },
  { label: 'Land & Sites For Sale', value: 'landForSale' },
  { label: 'Land & Sites For Rent', value: 'landForRent' },
  { label: 'Commercial For Sale', value: 'commercialForSale' },
  { label: 'Commercial For Rent', value: 'commercialForRent' },
  { label: 'Agricultural', value: 'agriculturalForSale' },
  { label: 'Agricultural Rentals', value: 'agriculturalForRent' },
];

export const CURRENCY_OPTIONS = [
  { label: '£', value: 'GBP' },
  { label: '€', value: 'EUR' },
];

export const SORT_ORDER_OPTIONS = [
  { label: 'Sort by', value: '' },
  { label: 'Most Viewed', value: 'hot' },
  { label: 'Recently Added', value: 'dateHigh' },
  { label: 'Recently Updated', value: 'updatedHigh' },
  { label: 'Price (Low to High)', value: 'priceLow' },
  { label: 'Price (High to Low)', value: 'priceHigh' },
  { label: 'Beds (Low to High)', value: 'bedroomsLow' },
  { label: 'Beds (High to Low)', value: 'bedroomsHigh' },
];

export const AUCTION_SORT_ORDER_OPTIONS = [...SORT_ORDER_OPTIONS, { label: 'Auction Date', value: 'auctionDate' }];

export const PRICE_OPTIONS = (symbol: string, suffix: string, suffixFull?: string) => [
  { label: 'Any', value: '' },
  { label: `${symbol}20,000${suffix}`, ariaLabel: `${symbol}20,000 ${suffixFull}`, value: '20000' },
  { label: `${symbol}30,000${suffix}`, ariaLabel: `${symbol}30,000 ${suffixFull}`, value: '30000' },
  { label: `${symbol}40,000${suffix}`, ariaLabel: `${symbol}40,000 ${suffixFull}`, value: '40000' },
  { label: `${symbol}50,000${suffix}`, ariaLabel: `${symbol}50,000 ${suffixFull}`, value: '50000' },
  { label: `${symbol}60,000${suffix}`, ariaLabel: `${symbol}60,000 ${suffixFull}`, value: '60000' },
  { label: `${symbol}70,000${suffix}`, ariaLabel: `${symbol}70,000 ${suffixFull}`, value: '70000' },
  { label: `${symbol}80,000${suffix}`, ariaLabel: `${symbol}80,000 ${suffixFull}`, value: '80000' },
  { label: `${symbol}90,000${suffix}`, ariaLabel: `${symbol}90,000 ${suffixFull}`, value: '90000' },
  { label: `${symbol}100,000${suffix}`, ariaLabel: `${symbol}100,000 ${suffixFull}`, value: '100000' },
  { label: `${symbol}110,000${suffix}`, ariaLabel: `${symbol}110,000 ${suffixFull}`, value: '110000' },
  { label: `${symbol}120,000${suffix}`, ariaLabel: `${symbol}120,000 ${suffixFull}`, value: '120000' },
  { label: `${symbol}130,000${suffix}`, ariaLabel: `${symbol}130,000 ${suffixFull}`, value: '130000' },
  { label: `${symbol}140,000${suffix}`, ariaLabel: `${symbol}140,000 ${suffixFull}`, value: '140000' },
  { label: `${symbol}150,000${suffix}`, ariaLabel: `${symbol}150,000 ${suffixFull}`, value: '150000' },
  { label: `${symbol}160,000${suffix}`, ariaLabel: `${symbol}160,000 ${suffixFull}`, value: '160000' },
  { label: `${symbol}170,000${suffix}`, ariaLabel: `${symbol}170,000 ${suffixFull}`, value: '170000' },
  { label: `${symbol}180,000${suffix}`, ariaLabel: `${symbol}180,000 ${suffixFull}`, value: '180000' },
  { label: `${symbol}190,000${suffix}`, ariaLabel: `${symbol}190,000 ${suffixFull}`, value: '190000' },
  { label: `${symbol}200,000${suffix}`, ariaLabel: `${symbol}200,000 ${suffixFull}`, value: '200000' },
  { label: `${symbol}210,000${suffix}`, ariaLabel: `${symbol}210,000 ${suffixFull}`, value: '210000' },
  { label: `${symbol}220,000${suffix}`, ariaLabel: `${symbol}220,000 ${suffixFull}`, value: '220000' },
  { label: `${symbol}230,000${suffix}`, ariaLabel: `${symbol}230,000 ${suffixFull}`, value: '230000' },
  { label: `${symbol}240,000${suffix}`, ariaLabel: `${symbol}240,000 ${suffixFull}`, value: '240000' },
  { label: `${symbol}250,000${suffix}`, ariaLabel: `${symbol}250,000 ${suffixFull}`, value: '250000' },
  { label: `${symbol}275,000${suffix}`, ariaLabel: `${symbol}275,000 ${suffixFull}`, value: '275000' },
  { label: `${symbol}300,000${suffix}`, ariaLabel: `${symbol}300,000 ${suffixFull}`, value: '300000' },
  { label: `${symbol}325,000${suffix}`, ariaLabel: `${symbol}325,000 ${suffixFull}`, value: '325000' },
  { label: `${symbol}350,000${suffix}`, ariaLabel: `${symbol}350,000 ${suffixFull}`, value: '350000' },
  { label: `${symbol}375,000${suffix}`, ariaLabel: `${symbol}375,000 ${suffixFull}`, value: '375000' },
  { label: `${symbol}400,000${suffix}`, ariaLabel: `${symbol}400,000 ${suffixFull}`, value: '400000' },
  { label: `${symbol}425,000${suffix}`, ariaLabel: `${symbol}425,000 ${suffixFull}`, value: '425000' },
  { label: `${symbol}450,000${suffix}`, ariaLabel: `${symbol}450,000 ${suffixFull}`, value: '450000' },
  { label: `${symbol}475,000${suffix}`, ariaLabel: `${symbol}475,000 ${suffixFull}`, value: '475000' },
  { label: `${symbol}500,000${suffix}`, ariaLabel: `${symbol}500,000 ${suffixFull}`, value: '500000' },
  { label: `${symbol}600,000${suffix}`, ariaLabel: `${symbol}600,000 ${suffixFull}`, value: '600000' },
  { label: `${symbol}700,000${suffix}`, ariaLabel: `${symbol}700,000 ${suffixFull}`, value: '700000' },
  { label: `${symbol}800,000${suffix}`, ariaLabel: `${symbol}800,000 ${suffixFull}`, value: '800000' },
  { label: `${symbol}900,000${suffix}`, ariaLabel: `${symbol}900,000 ${suffixFull}`, value: '900000' },
  { label: `${symbol}1,000,000${suffix}`, ariaLabel: `${symbol}1,000,000 ${suffixFull}`, value: '1000000' },
  { label: `${symbol}1,250,000${suffix}`, ariaLabel: `${symbol}1,250,000 ${suffixFull}`, value: '1250000' },
  { label: `${symbol}1,500,000${suffix}`, ariaLabel: `${symbol}1,500,000 ${suffixFull}`, value: '1500000' },
  { label: `${symbol}1,750,000${suffix}`, ariaLabel: `${symbol}1,750,000 ${suffixFull}`, value: '1750000' },
  { label: `${symbol}2,000,000${suffix}`, ariaLabel: `${symbol}2,000,000 ${suffixFull}`, value: '2000000' },
];

export const RENTAL_PRICE_OPTIONS = (symbol: string, suffix: string, suffixFull: string) => [
  { label: 'Any', value: '' },
  { label: `${symbol}100${suffix}`, ariaLabel: `${symbol}100 ${suffixFull}`, value: '100' },
  { label: `${symbol}150${suffix}`, ariaLabel: `${symbol}150 ${suffixFull}`, value: '150' },
  { label: `${symbol}200${suffix}`, ariaLabel: `${symbol}200 ${suffixFull}`, value: '200' },
  { label: `${symbol}250${suffix}`, ariaLabel: `${symbol}250 ${suffixFull}`, value: '250' },
  { label: `${symbol}300${suffix}`, ariaLabel: `${symbol}300 ${suffixFull}`, value: '300' },
  { label: `${symbol}350${suffix}`, ariaLabel: `${symbol}350 ${suffixFull}`, value: '350' },
  { label: `${symbol}400${suffix}`, ariaLabel: `${symbol}400 ${suffixFull}`, value: '400' },
  { label: `${symbol}450${suffix}`, ariaLabel: `${symbol}450 ${suffixFull}`, value: '450' },
  { label: `${symbol}500${suffix}`, ariaLabel: `${symbol}500 ${suffixFull}`, value: '500' },
  { label: `${symbol}550${suffix}`, ariaLabel: `${symbol}550 ${suffixFull}`, value: '550' },
  { label: `${symbol}600${suffix}`, ariaLabel: `${symbol}600 ${suffixFull}`, value: '600' },
  { label: `${symbol}700${suffix}`, ariaLabel: `${symbol}700 ${suffixFull}`, value: '700' },
  { label: `${symbol}800${suffix}`, ariaLabel: `${symbol}800 ${suffixFull}`, value: '800' },
  { label: `${symbol}900${suffix}`, ariaLabel: `${symbol}900 ${suffixFull}`, value: '900' },
  { label: `${symbol}1,000${suffix}`, ariaLabel: `${symbol}1,000 ${suffixFull}`, value: '1000' },
  { label: `${symbol}1,250${suffix}`, ariaLabel: `${symbol}1,250 ${suffixFull}`, value: '1250' },
  { label: `${symbol}1,500${suffix}`, ariaLabel: `${symbol}1,5000 ${suffixFull}`, value: '1500' },
  { label: `${symbol}2,000${suffix}`, ariaLabel: `${symbol}2,000 ${suffixFull}`, value: '2000' },
];

export const BEDROOM_OPTIONS = (suffix: string, suffixFull: string) => [
  { label: 'Any', value: '' },
  { label: `1 Bed ${suffix}`, ariaLabel: `1 Bed ${suffixFull}`, value: '1' },
  { label: `2 Beds ${suffix}`, ariaLabel: `2 Beds ${suffixFull}`, value: '2' },
  { label: `3 Beds ${suffix}`, ariaLabel: `3 Beds ${suffixFull}`, value: '3' },
  { label: `4 Beds ${suffix}`, ariaLabel: `4 Beds ${suffixFull}`, value: '4' },
  { label: `5 Beds ${suffix}`, ariaLabel: `5 Beds ${suffixFull}`, value: '5' },
  { label: `6 Beds ${suffix}`, ariaLabel: `6 Beds ${suffixFull}`, value: '6' },
];

export const HEATING_OPTIONS = [
  { label: 'Oil', value: 'OIL' },
  { label: 'Gas', value: 'GAS' },
  { label: 'Solid Fuel', value: 'SOLID' },
  { label: 'Electric', value: 'ELECTRIC' },
  { label: 'Eco', value: 'ECO' },
];

export const EPC_OPTIONS = [
  { label: 'Any', value: '' },
  { label: 'A', ariaLabel: 'A', value: 'A' },
  { label: 'B and above', ariaLabel: 'B and above', value: 'B' },
  { label: 'C and above', ariaLabel: 'C and above', value: 'C' },
  { label: 'D and above', ariaLabel: 'D and above', value: 'D' },
  { label: 'E and above', ariaLabel: 'E and above', value: 'E' },
  { label: 'F and above', ariaLabel: 'F and above', value: 'F' },
  { label: 'G and above', ariaLabel: 'G and above', value: 'G' },
];

export const YEAR_BUILT_OPTIONS = [
  { label: 'Any', value: '' },
  { label: 'Last 5 Years', ariaLabel: 'Last 5 Years', value: `${dayjs().subtract(5, 'years').format('YYYY')}-0` },
  { label: 'Last 10 Years', ariaLabel: 'Last 10 Years', value: `${dayjs().subtract(10, 'years').format('YYYY')}-0` },
  { label: 'Post 1990', ariaLabel: 'Post 1990', value: '1990-0' },
  { label: '1966-1990', ariaLabel: '1966-1990', value: '1966-1990' },
  { label: '1946-1965', ariaLabel: '1946-1965', value: '1946-1965' },
  { label: '1920-1945', ariaLabel: '1920-1945', value: '1920-1945' },
  { label: 'Pre 1919', ariaLabel: 'Pre 1919', value: '0-1919' },
];

export const FLOOR_SIZE_OPTIONS = [
  { label: 'Any', value: '' },
  { label: '500+ Sq ft (46.5+ Sq m)', ariaLabel: '500+ Sq ft (46.5+ Sq m)', value: '46.5' },
  { label: '750+ Sq ft (70+ Sq m)', ariaLabel: '750+ Sq ft (70+ Sq m)', value: '70' },
  { label: '1,000+ Sq ft (93+ Sq m)', ariaLabel: '1,000+ Sq ft (93+ Sq m)', value: '93' },
  { label: '1,500+ Sq ft (139+ Sq m)', ariaLabel: '1,500+ Sq ft (139+ Sq m)', value: '139' },
  { label: '2,000+ Sq ft (186+ Sq m)', ariaLabel: '2,000+ Sq ft (186+ Sq m)', value: '186' },
];

export const BROADBAND_OPTIONS = [
  { label: 'Any', value: '' },
  { label: '30 Mbps+ (Superfast)', ariaLabel: '30 Mbps+ (Superfast)', value: 'SUPERFAST' },
  { label: '300 Mbps+ (Ultrafast)', ariaLabel: '300 Mbps+ (Ultrafast)', value: 'ULTRAFAST' },
  { label: '900 Mbps+ (Ultrafast)', ariaLabel: '900 Mbps+ (Ultrafast)', value: 'ULTRAFAST900' },
];
