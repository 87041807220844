import { createSlice } from '@reduxjs/toolkit';
import { Pal } from '../../types/pals';
import { RootState } from '../store';

export interface PalsState {
  data?: Pal[];
  error?: Error;
  loading: boolean;
}

const initialState: PalsState = {
  loading: false,
};

interface SetPals {
  payload: Pal[];
}

interface SetError {
  payload: Error;
}

interface SetLoading {
  payload: boolean;
}

export const enquiriesSlice = createSlice({
  name: 'pals',
  initialState,
  reducers: {
    setPals: (state, action: SetPals) => {
      state.data = action.payload;
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    clearPals: (state) => {
      state = { ...initialState };

      return state;
    },
  },
});

export const { clearPals, setPals, setError, setLoading } = enquiriesSlice.actions;

export const selectPals = (state: RootState) => state.pals;

export default enquiriesSlice.reducer;
