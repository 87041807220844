// Browser cannot get httpOnly cookies, therefore on the client they are only stored in memory
import { NetworkContext } from '../types/network';

const browserCache: { [key: string]: any } = {};

export const getItem = async (key: string, _?: NetworkContext) => {
  return browserCache[key] !== undefined ? browserCache[key] : null;
};

export const setItem = async (key: string, value: any, _?: NetworkContext) => {
  browserCache[key] = value;

  return null;
};

export const removeItem = async (key: string, _?: NetworkContext) => {
  delete browserCache[key];

  return null;
};
