import { createSlice } from '@reduxjs/toolkit';
import { HeatingType, SavedSearchResult, SearchFormNlpResult, SearchParams } from '../../types/search';
import { RootState } from '../store';

interface SavedSearchState {
  data?: SavedSearchResult;
  loading: boolean;
  error?: Error;
}

export interface SearchState {
  text: string;
  asTypedPristine: string | null;
  view: 'map' | 'list';
  nlpData?: SearchFormNlpResult;
  useOnLaunch: boolean;
  filters: {
    radius: string;
    expand: string;
    category: string;
    currency: string;
    sortOrder: string;
    minPrice: string;
    maxPrice: string;
    minBeds: string;
    maxBeds: string;
    filter: Array<{ filterValue: string; description: string }>;
    includeGone: boolean;
    includeAgreed: boolean;
    excludePoa: boolean;
    excludeBuildingSites: boolean;
    excludeStudentLettings: boolean;
    unfurnished: boolean;
    furnished: boolean;
    partiallyFurnished: boolean;
    optionalFurnished: boolean;
    saleType: string;
    styles: string[];
    lat: number | null;
    lng: number | null;
    polyIds: number[];
    term: string;
    heatingType: HeatingType[];
    epc: string;
    includePotentialEpc: boolean;
    yearBuilt: string;
    minInternalArea: string;
    broadband: string;
    garage: boolean;
    largeGarage: boolean;
    garageSize: string | null;
    tags: string[] | null;
    excludeTags: string[] | null;
    coOwnership: boolean;
    excludeNewHomes: boolean;
  };
  resultParams?: SearchParams;
  savedStats: {
    totalPersonalGroups: number;
    totalGroups: number;
    totalOtherGroups: number;
  };
  saved: {
    PERSONAL: SavedSearchState;
    FULL: SavedSearchState;
    FRIENDS: SavedSearchState;
    [key: string]: SavedSearchState;
  };
}

export const initialState: SearchState = {
  text: '',
  asTypedPristine: null,
  view: 'list',
  useOnLaunch: false,
  filters: {
    radius: '',
    expand: '',
    category: 'residential',
    currency: '',
    sortOrder: '',
    minPrice: '',
    maxPrice: '',
    minBeds: '',
    maxBeds: '',
    filter: [],
    includeGone: false,
    includeAgreed: false,
    excludePoa: false,
    excludeBuildingSites: false,
    excludeStudentLettings: false,
    unfurnished: false,
    furnished: false,
    partiallyFurnished: false,
    optionalFurnished: false,
    saleType: 'sale',
    styles: [],
    lat: null,
    lng: null,
    polyIds: [],
    term: '',
    heatingType: [],
    epc: '',
    includePotentialEpc: false,
    yearBuilt: '',
    minInternalArea: '',
    broadband: '',
    garage: false,
    largeGarage: false,
    garageSize: null,
    tags: null,
    excludeTags: null,
    coOwnership: false,
    excludeNewHomes: false,
  },
  savedStats: {
    totalPersonalGroups: 0,
    totalGroups: 0,
    totalOtherGroups: 0,
  },
  saved: {
    PERSONAL: {
      loading: false,
    },
    FULL: {
      loading: false,
    },
    FRIENDS: {
      loading: false,
    },
  },
};

interface SetSearchText {
  payload: string;
}

interface SetSearchResultParams {
  payload: SearchState['resultParams'];
}

interface SetSearch {
  payload: {
    text: string;
    filters: SearchState['filters'];
  };
}

interface SetSearchParams {
  payload: SearchFormNlpResult;
}

interface SetSavedSearches {
  payload: {
    data: SavedSearchResult;
    view: string;
  };
}

interface SetMoreSavedSearch {
  payload: {
    data: SavedSearchResult;
    view: string;
  };
}

interface SetSavedSearchesError {
  payload: {
    error: Error;
    view: string;
  };
}

interface SetSavedSearchesLoading {
  payload: {
    loading: boolean;
    view: string;
  };
}

interface RemoveSavedSearch {
  payload: {
    id: number;
    selfOwned: boolean;
  };
}

interface SetSavedSearch {
  payload: {
    id: number;
    emailFrequency: string;
    searchId: number;
  };
}

interface SetFilters {
  payload: SearchState['filters'];
}

interface SetView {
  payload: SearchState['view'];
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchText: (state, action: SetSearchText) => {
      state.text = action.payload;
    },
    setSearchResultParams: (state, action: SetSearchResultParams) => {
      state.resultParams = { ...action.payload };
    },
    setSearch: (state, action: SetSearch) => {
      state.text = action.payload.text;

      if (action.payload.filters) {
        state.filters = { ...action.payload.filters };
      }
    },
    setSearchParams: (state, action: SetSearchParams) => {
      state.asTypedPristine = action.payload.phrase.asTypedPristine;
      state.nlpData = action.payload;
    },
    setFilters: (state, action: SetFilters) => {
      state.filters = { ...action.payload };
    },
    setSavedSearches: (state, action: SetSavedSearches) => {
      const { view, ...payload } = action.payload;

      state.saved[view].data = payload.data;
      state.saved[view].loading = false;

      state.savedStats.totalGroups = payload.data.totalGroups;
      state.savedStats.totalOtherGroups = payload.data.totalOtherGroups;
      state.savedStats.totalPersonalGroups = payload.data.totalPersonalGroups;
    },
    setMoreSavedSearches: (state, action: SetMoreSavedSearch) => {
      const { view, ...payload } = action.payload;

      const saved = state.saved[view];

      if (saved.data) {
        saved.data = {
          ...payload.data,
          content: [...saved.data.content, ...payload.data.content],
        };
        saved.loading = false;
      }
    },
    setSavedSearchesError: (state, action: SetSavedSearchesError) => {
      state.saved[action.payload.view].error = action.payload.error;
      state.saved[action.payload.view].loading = false;
    },
    setSavedSearchesLoading: (state, action: SetSavedSearchesLoading) => {
      state.saved[action.payload.view].error = undefined;
      state.saved[action.payload.view].loading = action.payload.loading;
    },
    removeSavedSearch: (state, action: RemoveSavedSearch) => {
      let isFriendOwned = false;
      Object.keys(state.saved).forEach((key) => {
        const saved = state.saved[key];

        if (saved.data) {
          saved.data.content = saved.data.content.filter((searchRow) => {
            if (searchRow.search.id === action.payload.id) {
              searchRow.selfOwned = false;
              isFriendOwned = searchRow.friendOwned;
              // remove current user from user list if friend owned
              if (searchRow.friendOwned) searchRow.users = searchRow.users.filter((u) => !u.self);
              // only remove items that are self owned and not friend owned and handle removing from PERSONAL
              // even when friend owned, so it removes friend owned searches that are not self owned on PERSONAL
              return searchRow.friendOwned && key !== 'PERSONAL';
            }
            return true;
          });
        }
      });

      if (action.payload.selfOwned) state.savedStats.totalPersonalGroups -= 1;
      // only removed from list if not friend owned
      else if (!isFriendOwned) {
        state.savedStats.totalOtherGroups -= 1;
        state.savedStats.totalGroups -= 1;
      }
    },
    setSavedSearch: (state, action: SetSavedSearch) => {
      Object.keys(state.saved).forEach((key) => {
        const saved = state.saved[key];

        if (saved.data) {
          saved.data.content = saved.data.content.map((searchRow) => {
            if (searchRow.search.id === action.payload.id) {
              const hasSelf = searchRow.users.find((u) => u.self);
              // only add self user when current user is not already included in users array
              if (!hasSelf)
                searchRow.users.push({ firstName: '', surname: '', self: true, avatar: '', timeOfOccurance: '' });

              return {
                ...searchRow,
                search: {
                  ...searchRow.search,
                  id: action.payload.searchId, // handling when subscribing to new alert
                  emailFrequency: action.payload.emailFrequency,
                },
                selfOwned: true,
                users: searchRow.users,
              };
            }

            return searchRow;
          });
        }
      });
    },
    clearSearches: (state) => {
      state = { ...initialState };

      return state;
    },
    resetFilters: (state) => {
      state.filters = { ...initialState.filters };
    },
    clearNlpData: (state) => {
      state.nlpData = undefined;
    },
    setView: (state, action: SetView) => {
      state.view = action.payload;
    },
    setUseOnLaunch: (state, action) => {
      state.useOnLaunch = action.payload;
    },
  },
});

export const {
  clearSearches,
  clearNlpData,
  resetFilters,
  removeSavedSearch,
  setFilters,
  setSavedSearches,
  setMoreSavedSearches,
  setSavedSearchesError,
  setSavedSearchesLoading,
  setSavedSearch,
  setSearchParams,
  setSearch,
  setSearchText,
  setSearchResultParams,
  setView,
  setUseOnLaunch,
} = searchSlice.actions;

export const selectSearch = (state: RootState) => state.search;

export default searchSlice.reducer;
